import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export default new Vuex.Store({
  state:{
      isMobile:false
  },
  mutations:{
      // 判断是不是手机端
      SET_MOBLIE(state,isMobile){
          state.isMobile = isMobile;
      }
  }
})
