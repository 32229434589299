import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import Vue2OrgTree from "vue2-org-tree";
import "vue2-org-tree/dist/style.css";
import VueI18n from "vue-i18n";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCnMqgY-4PTmqW9kVrFp5sY4DiUEzwt7_c",
    libraries: "places" // This is required if you use the Autocomplete plugin
  }
});
Vue.use(VueAwesomeSwiper /* { default global options } */);
Vue.use(VueI18n);
Vue.use(Vue2OrgTree);
Vue.use(VueVideoPlayer);
Vue.use(ElementUI);
/* 重置样式 */
import "./assets/css/reset.min.css";

/* bootstarp */
import "./assets/css/bootstrap.min.css";
Vue.config.productionTip = false;

let locale = window.localStorage.getItem("locale");

if (!locale) {
  // let jsSrc = (navigator.language || navigator.browserLanguage).toLowerCase();
  // if (jsSrc.indexOf("zh") >= 0) {
  //   locale = "tw";
  // } else if (jsSrc.indexOf("en") >= 0) {
  //   locale = "en";
  // } else if (jsSrc.indexOf("ja") >= 0) {
  //   locale = "jp";
  // } else {
  //   locale = "ko";
  // }
  locale = "en";
}

const i18n = new VueI18n({
  locale: locale ? locale : "en",
  messages: {
    "en": require("./lang/en").m, // 英语
    "ko": require("./lang/ko").m, // 韩语
    "jp": require("./lang/jp").m, // 日语
    "tw": require("./lang/tw").m // 繁体中文
  },
  silentTranslationWarn: true
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");

export default {
  app
};
