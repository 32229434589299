export const m = {
  "검색어를 입력해주세요": "検索ワードを入力してください。",
  "제 1 조 총칙": "第1条 総則",
  "1. ㈜팡스카이(이하 ‘회사’라 합니다.)는 서비스를 제공하기 위해 필요한 필수항목을 이용자의 동의 하에 수집하고 있습니다. 개인정보란 생존하는 개인에 관한 정보로서 해당 정보에 포함되어 있는 성명 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보를 말합니다.":
    "1. (株)Pangsky (以下「当社」といいます。)はサービスを提供するために必要な必須項目を利用者の同意の下に収集します。 個人情報とは、生存する個人に関する情報であり、当該情報に含まれている氏名等の事項によって当該個人を識別できる情報をいいます。",
  "2. 회사는 이용자의 개인정보를 소중히 처리하며, 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 방송통신위원회가 제정한 『개인정보보호지침』을 준수하고 있습니다.":
    "2. 当社は、利用者の個人情報を厳格に管理し、電気通信事業法、個人情報保護法等の情報保護に関する法律を遵守しています。",
  "3. 회사는 관련 법령에 의거하여 개인정보처리방침을 통해 이용자의 권익 보호를 위해 최선을 다하고 있습니다.":
    "3. 当社は、関連法令に基づき、プライバシーポリシー（以下「本ポリシー」といいます。）を定め、利用者の権益保護のために最善を尽くしております。",
  "4. 회사는 개인정보처리방침을 홈페이지 첫 화면 하단에 공개함으로써 이용자가 언제나 용이하게 보실 수 있도록 조치하고 있습니다.":
    "4. 当社は、本ポリシーをホームページの最初の画面下段に公開することにより、利用者がいつでも容易にご覧になれるよう措置しています。",
  "제 2 조 수집하는 개인정보 항목 및 수집방법":
    "第2条 収集する個人情報の項目及び収集方法",
  "1. 회사는 회원가입 시 서비스 제공을 위해 필요한 최소한의 개인정보만을 수집합니다.":
    "1. 当社は会員登録時にサービスを提供するために必要な最小限の個人情報のみを収集します。",
  "2. 이용자가 회사의 서비스를 이용하기 위해서는 회원가입 시 개인정보를 입력하셔야 하며, 회사는 필수입력항목과 선택입력항목으로 구분하여 개인정보를 수집하고 있습니다. 필수입력항목은 회원가입을 위해 반드시 제공해야 하는 개인정보이며, 선택입력항목은 입력하지 않으셔도 회원가입이 가능한 개인정보를 의미합니다. 필수항목과 선택항목은 다음과 같습니다.":
    "2. 利用者が当社のサービスを利用するためには、会員登録の際に個人情報を入力しなければならず、当社は必須入力項目と選択入力項目に分けて個人情報を収集しています。必須入力項目は、会員登録のために必ず提供しなければならない個人情報であり、選択入力項目は入力しなくても会員登録が可能な個人情報を意味します。必須項目と選択項目は、以下の通りです。",
  "[일반회원]": "［一般会員］",
  "- 필수항목 : 이름, 아이디(ID), 비밀번호, 만 14세 미만인 경우 법정대리인 정보, 전화번호, 휴대전화번호, 이용자정보 확인 질문/답변 주로 쓰는 이메일, 네이버, 페이스북, 구글 등의 SNS 계정 정보, CI, DI":
    "- 必須項目：氏名、ID、パスワード、満１５歳未満の場合法定代理人の情報、電話番号、携帯の電話番号、利用者情報の確認質問/回答、メールアドレス、Naver、Facebook、Google等のSNSアカウント情報、CI、DI",
  "3. 수집방법": "3. 収集方法： ",
  "홈페이지(이용자가입), 서면양식, 전화, 기타방법":
    "ホームページ(利用者加入)、書面(様式)、電話その他の方法",
  "4. 회사는 설문조사, 경품발송 등을 위한 목적으로 이용자의 개인정보 기재를 요청하거나 보다 나은 서비스를 위하여 소프트웨어 정보를 추출할 수 있으며, 수집한 목적 등이 완료된 경우에는 관계법령에서 정한 경우를 제외하고 수집된 개인정보가 열람 또는 이용될 수 없도록 처리합니다.":
    "4. 当社は、アンケート調査、景品の発送等のために利用者の個人情報の記載を要請したり、より良いサービスのためにソフトウェア情報を抽出することができ、収集した目的等が完了した場合には、関係法令が定める場合を除き、収集された個人情報が閲覧または利用されないよう処理します。",
  "5. 유료서비스를 이용하고자 하는 이용자는 본인의 이용료결제에 필요한 범위내에서 은행계좌정보, 신용카드정보 등의 항목을 입력해야 하며 이는 이용료 결제를 위한 목적으로 사용됩니다.":
    "5. 有料サービスを利用しようとする利用者は、本人の利用料決済に必要な範囲内で銀行口座情報、クレジットカード情報などの項目を入力しなければならず、これは利用料決済の目的で使用されます。",
  "6. 회사는 서비스의 불량이용자 규제를 위해 IP주소, 쿠키정보, 불량이용기록 등을 수집할 수 있습니다.":
    "6. 当社は、サービスの不正利用を規制するため、IPアドレス、クッキー情報、不正利用記録等を収集できます。",
  "제 3 조 개인정보 수집 및 이용에 대한 동의":
    "第3条 個人情報の収集及び利用への同意",
  "회사는 이용자의 개인정보 수집 및 이용에 대한 동의를 받고 있습니다. 이용자의 개인정보 수집과 관련하여 회원가입 시 개인정보처리방침에 대한 동의절차를 마련하여, 동의 버튼을 클릭하면 개인정보 수집 및 이용에 대해 동의한 것으로 봅니다. 다만, 아래의 경우에는 개인정보 수집 및 이용에 대한 이용자의 사전 동의를 구하지 않을 수 있습니다.":
    "当社は、利用者の個人情報収集及び利用に関する同意を得ています。利用者の個人情報収集に関して会員登録時の本ポリシーに対する同意手続きを設け、同意ボタンをクリックすると個人情報収集及び利用に同意したものとみなされます。 ただし、以下の場合は個人情報の収集及び利用に対する利用者の事前同意を得ないことがあります。",
  "1. 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한 개인정보로서 경제적 기술적인사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우":
    "1. 情報通信サービスの提供に関する契約を履行するために必要な個人情報であって、経済的·技術的な事由により通常の同意を得ることが明らかに困難である場合",
  "2. 정보통신서비스의 제공에 따른 요금정산을 위하여 필요한 경우":
    "2. 情報通信サービスの提供に伴う料金精算のために必要な場合",
  "3. 다른 법률에 특별한 규정이 있는 경우": "3. 他の法律に特別な規定がある場合",
  "제 4 조 개인정보의 수집 및 이용목적": "第4条 個人情報の収集及び利用目的",
  "1. 회원 가입 시 수집하는 개인정보 별 수집목적은 다음과 같습니다.":
    "1. 会員登録時に収集する個人情報別の収集目的は、以下のとおりです。",
  "(1) 이름(실명), 아이디, 비밀번호: 신용평가기관을 통한 실명확인, 중복가입확인, 서비스 이용에 따른 본인식별, 연령제한 서비스, 인구통계학적 분석, 고객센터의 운영, 불량이용자의 부정한 이용재발 방지":
    "(1) 名前(実名)、ID、パスワード：信用評価機関による実名確認、重複加入の確認、サービス利用による本人識別、年齢制限サービス、人口統計分析、カスタマーセンターの運営、利用者の不正利用の再発防止",
  "(2) 비밀번호 힌트/답: 이용자가 비밀번호를 분실한 경우 이에 고객지원을 위한 본인확인용 질문/답":
    "(2) パスワードのヒント/答え：利用者がパスワードを紛失した場合、お客様サポートのための本人確認用の質問と答え",
  "(3) 전자우편: 공지사항 전달, 본인 의사확인, 고객문의 답변 등 원활한 의사소통 채널확보, 신규 서비스 나 이벤트 정보 등 최신 정보 등의 안내":
    "(3) メールアドレス：お知らせ、本人の意思確認、お問い合わせなど円滑なコミュニケーションチャンネル確保、新規サービスやイベント情報などの最新情報案内",
  "(4) 거주지 주소: 지역별 이벤트 등의 정보 제공, 지역별 커뮤니티 서비스 제공":
    "(4) 住所：地域別イベントなどの情報提供、地域別コミュニティサービスの提供",
  "(5) 연락처(전화번호/휴대폰): 비밀번호 분실 시 SMS 통보 및 고객지원을 위한 연락처 확보":
    "(5) 連絡先(電話番号/携帯電話)：パスワードの紛失時SMS通知および顧客サポートのための連絡先を確保",
  "(6) 부모님(법정대리인) 성명 14세 미만 이용자(이하 “아동”이라 합니다)의 개인정보 수집시 법정대리인 동의를 얻기 위한 실명 확인 및 법정 대리인의 아동 개인정보 열람 등을 위한 고객지원, 만18세 미만 청소년의 가입시 법정대리인 동의를 얻기 위한 실명 확인 및 게임물 이용내역의 고지":
    "(6) 両親(法定代理人)の氏名：１５歳未満の利用者(以下「児童」といいます。)の個人情報収集の際、法定代理人の同意を得るための実名確認及び法定代理人の児童個人情報閲覧等のための顧客支援、満１８歳未満の青少年の加入時に法定代理人の同意を得るための実名確認及びゲーム利用内訳の告知",
  "(7) 휴대폰 소유정보, 신용카드 소유정보, 본인확인 서류: 가입 시 혹은 기타 개인정보 변경 요청 시 본인확인을 위한 인증수단":
    "(7) 携帯の所有情報、クレジットカード所有情報、本人確認書類：登録時、またはその他の個人情報変更要請時の本人確認のための認証手段",
  "제 5 조 조 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항":
    "第5条 個人情報自動収集装置の設置、運営及びその拒否に関する事項",
  "■ 쿠키란?": "■ クッキーとは？",
  "특정 웹사이트에서 웹서버가 웹브라우저에게 전달하는 작은 텍스트 파일 서비스 개선을 위해 쿠키를 이용하여 이용자의 접속 기록, 이용 형태 등을 파악합니다.":
    "クッキーとは、ウェブサイトを訪問した際の利用履歴や入力情報等を訪問者のコンピューターに保存する仕組みです。クッキーを使用することにより、次回そのウェブサイトを訪問した場合に、訪問者のコンピューターを識別し、利用状況に応じた情報の提供を受けることができます。",
  "이용자는 브라우저에서 옵션 설정을 통해 쿠키가 수집되지 않도록 거부할 수 있습니다.":
    "利用者はブラウザでオプション設定によりクッキーを収集しないよう拒否することができます。",
  " 고급": "高級",
  " 쿠키 그 외 브라우저의 경우 브라우저 별 설정 방식에 따릅니다.":
    "クッキー以外のブラウザの場合、ブラウザごとの設定方法に従います。",
  "■ 웹로그 분석이란?": "■ ウェブログ分析(利用状況分析)とは？",
  "웹사이트상에서 이용자의 서비스 이용 형태에 대한 분석":
    "ウェブサイト上での利用者のサービス利用形態の分析",
  "oogle Analytics와 같이 다양한 외부 웹로그분석 도구를 사용할 수 있으며 Google Analytics의 경우 데이터가 사용되는 것을 거부할 수 있습니다.":
    "Google Analyticsのように様々な外部ウェブログ分析ツールを使用でき、Google Analyticsの場合、データの使用を拒否することができます。",
  "- Google Analytics 차단: https://tools.google.com/dlpage/gaoptout/ 그 외 웹로그분석 도구의 경우 도구 별 거부 방식에 따릅니다.":
    "- Google Analyticsの拒否：https://tools.google.com/dlpage/gaoptout その他のウェブログ分析ツールの場合、ツール別の拒否方法に従います。",
  "제 6 조 개인정보의 제공": "第6条 個人情報の提供",
  "1. 회사는 이용자의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 어떠한 경우에도 [개인정보의 수집목적 및 이용목적]에서 고지한 범위를 넘어 이용자의 개인정보를 이용하거나 타인 또는 타기업/기관에 제공하지 않습니다.":
    "1. 当社は、利用者の同意があったり、法令の定める場合を除き、いかなる場合であっても[個人情報の収集目的及び利用目的]で告知した範囲を超えて利用者の個人情報を利用したり、第3者または他企業機関に提供しません。",
  "2. 이용자의 개인정보를 제공하는 경우에는 개인정보를 제공받는 자, 개인정보를 제공받는 자의 개인정보 이용 목적, 제공하는 개인정보 항목, 개인정보를 제공받는 자의 개인정보 보유 및 이용기간에 대해 개별적으로 인터넷사이트, 전자우편, 서면, 전화 등을 통해 고지한 후 이에 대한 사전 동의를 구합니다.다만, 다음의 경우에는 이용자의 동의 없이 개인정보를 제공할 수 있습니다.":
    "2. 利用者の個人情報を提供する場合には、個人情報の提供を受ける者、個人情報の利用目的、提供する個人情報項目、個人情報の提供を受ける者の個人情報保有及び利用期間について、個別にインターネットサイト、電子メール、書面、電話等により告知した後、事前同意を求めます。ただし、以下の場合は利用者の同意なしに個人情報を提供することができます。",
  "(1) 서비스 제공에 따른 요금 정산을 위하여 필요한 경우":
    "(1) サービス提供に伴う料金精算のために必要な場合",
  "(2) 통계작성, 학술연구 또는 시장조사를 위하여 개인을 식별할 수 없는 형태로 제공하는 경우":
    "(2) 統計作成、学術研究または市場調査のために個人を識別できない形態で提供する場合",
  "(3) 법령에 특별한 규정이 있는 경우": "(3) 法令に特別な規定がある場合",
  "3. 회사는 관계 법령에 의하거나 수사기관의 요청에 의해 이용자의 개인정보를 제공한 경우 이를 당사자에게 고지하는 것을 원칙으로 합니다. 단, 법률상의 근거에 의해 부득이하게 고지를 못할 수도 있습니다.":
    "3. 当社は、関連法令の定めによるものや捜査機関の要請により利用者の個人情報を提供した場合、これを当事者に告知することを原則とします。ただし、法律上の根拠によりやむを得ず告知できない場合もあります。",
  "4. 회사는 아래와 같은 경우 이용자의 동의 하에 개인정보를 제3자에게 제공할 수 있습니다.":
    "4. 当社は、以下のような場合に利用者の同意の下、個人情報を第三者に提供できます。",
  "(1) 물품구매, 유료컨텐츠 이용 등의 배송 및 정산을 위해 이용자의 이름, 주소, 전화번호 등이 해당 쇼핑몰 업체, 유료컨텐츠 제공자, 배송업자에게 제공될 수 있습니다.":
    "(1) 物品の購入、有料コンテンツ利用などの配送及び精算のために利用者の名前、住所、電話番号等が当該ショッピングモール業者、有料コンテンツ提供者、配送業者に提供されることがあります。",
  "(2) 각종 이벤트 행사에 참여한 회원의 개인정보가 해당 이벤트의 주최자에게 제공될 수 있습니다.":
    "(2) 各種イベントに参加した会員の個人情報が、当該イベントの主催者に提供されることがあります。",
  "5. 회사는 이용자에게 제공되는 서비스의 질을 향상시키기 위해 맞춤 서비스, 온라인광고 서비스, 쇼핑몰 서비스, 커뮤니티 서비스, 유료컨텐츠 서비스, 모바일 서비스, 보험ㆍ신용카드 등의 텔레마케팅 서비스, 통계작성 또는 시장조사 등 다양한 서비스를 제공할 목적으로 여러 분야의 전문 컨텐츠 사업자 혹은 비즈니스 사업자와 함께 파트너쉽을 맺을 수 있습니다.":
    "5. 当社は、利用者に提供されるサービスの質を向上させるために、カスタマイズサービス、オンライン広告サービス、ショッピングモールサービス、コミュニティサービス、有料コンテンツサービス、モバイルサービス、保険·クレジットカード等のテレマーケティングサービス、統計作成又は市場調査等、様々なサービスを提供する目的で、様々な分野の専門コンテンツ事業者又はビジネス事業者と共同でパートナーシップを結ぶことがあります。",
  "제 7 조 개인정보의 처리위탁": "第7条 個人情報の処理委託",
  "1. 회사는 제3자에게 이용자의 개인정보를 수집ㆍ보관ㆍ처리ㆍ이용ㆍ제공ㆍ관리ㆍ감독 및 교육ㆍ파기 등(이하 ":
    "1. 当社は、第３者に利用者の個人情報を収集・保管・処理・利用・提供・管理・監督及び教育・破棄等（以下",
  "라 한다)을 할 수 있도록 업무를 위탁(이하 ":
    "「処理」といいます。)ができるように業務を委託(以下「個人情報処理委託」という。)",
  "이라 한다)하는 경우에는 다음 각 호의 사항 모두를 이용자에게 알리고 동의를 받습니다.":
    "する場合は、以下の各号の事項すべてを利用者に通知し、同意を得ます。",
  "(1) 개인정보 처리위탁을 받는 자(이하 ":
    "(1) 個人情報処理委託を受ける者（以下",
  "라 한다)": "「受託者」といいます。)",
  "(2) 개인정보 처리위탁을 하는 업무의 내용":
    "(2) 個人情報処理委託を行う業務の内容",
  "2. 회사는 이용자에게 다양하고 높은 수준의 서비스 제공을 위해 반드시 필요한 업무에 대해서는 아래와 같이 외부전문업체에 위탁하여 운영합니다.":
    "2. 会社は利用者に多様で高品質のサービスを提供するために必ず必要な業務については、以下のように外部の専門業者に委託して運営します。",
  수탁업체: "社名",
  위탁업무내용: "委託業務内容",
  "개인정보 이용기간": "個人情報の利用期間",
  "나이스신용평가정보㈜": "(韓国) NICE信用評価情報（株）",
  "이용자 본인 확인": "利用者本人確認",
  "별도 저장하지 않음": "別途保存しない",
  KG이니시스: "(韓国) KGイニシス",
  "유료서비스 결제처리 및 요금정산": "有料サービスの決済処理と料金精算",
  "결제일 기준 5년": "결제일 기준 5년：決済日から5年間",
  "제 8 조 이용자 및 법정대리인의 권리와 그 행사 방법":
    "第8条 利用者及び法定代理人の権利並びにその行使方法",
  "1. 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수 있습니다.":
    "1. 利用者及び法定代理人は、いつでも登録されている自分の個人情報を照会したり修正することができ、登録の解約を要請することができます。",
  "2. 이용자 혹은 만 14세 미만 아동의 개인정보 조회 및 수정을 위해서는 마이페이지의 ‘개인정보관리’를 가입해지를 위해서는 ‘회원탈퇴’ 메뉴를 이용해서 직접 열람, 정정 또는 탈퇴가 가능합니다. 또는 개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하도록 하겠습니다.":
    "2. 利用者又は満１５歳未満の児童の個人情報の照会・修正を行う際にはマイページから、「個人情報管理」登録を解約するためには、「会員退会」メニューから直接閲覧・訂正・退会を行うことができます。または個人情報保護責任者に書面、電話または電子メールで連絡していただければ遅滞なく措置いたします。",
  "3. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리 결과를 제 3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.":
    "3. 利用者が個人情報の誤りの訂正を要請した場合、訂正を完了するまで当該個人情報を利用または提供しません。また、誤った個人情報を第３者に提供した場合、訂正処理結果を第３者に遅滞なく通知し訂正するようにいたします。",
  "4. 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 ‘개인정보의 보유기간 및 이용기간’에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.":
    "4. 当社は、利用者又は法定代理人の要請によって解約又は削除された個人情報は、「個人情報の保有期間及び利用期間」に明示された内容に従って処理し、それ以外の用途で閲覧又は利用できないよう処理しています。",
  "제 9 조 개인정보의 보유기간 및 이용기간":
    "第9条 個人情報の保有期間及び利用期間",
  "1. 회사는 이용자가 이용자자격을 유지하고 있는 동안에는 이용자가 회사에 제공한 개인정보를 계속 보유합니다.":
    "1. 当社は、利用者が利用者の資格を維持している間、利用者が当社に提供した個人情報を保有し続けます。",
  "2. 개인정보제공에 대해 동의철회(회원탈퇴)할 경우 즉시 수집된 개인정보를 즉시 파기하며, 예외 규정을 제외하고 어떠한 목적으로도 사용할 수 없도록 합니다.":
    "2. 個人情報提供について同意撤回(会員退会)した場合、収集した個人情報を直ちに破棄し、例外規定を除き、いかなる目的であっても使用できないようにします。",
  "3. 일시적인 목적에 의해 수집한 개인정보는 그 이용목적을 달성하면 지체 없이 파기합니다. 단, 이벤트응모를 위해 주소 등의 개인정보를 제공한 경우에는 이벤트상품 발송 및 이벤트응모 고객에 대한 고객응대를 위해 해당 이벤트의 종료시점으로부터 6개월간 해당 개인정보를 보유합니다.":
    "3. 一時的な目的により収集した個人情報は、その利用目的を完了した場合直ちに破棄します。ただし、イベント応募のため住所等の個人情報を提供した場合は、イベント商品の発送及びイベント応募顧客に対する顧客応対のため、当該イベントの終了時点から6ヶ月間、当該個人情報を保有します。",
  "4. 이용자가 본인이 회사의 이용자임을 증빙하기 위해 회사에 발송한 신분증 사본 등 서류일체는 본인확인 후 즉시 파기합니다.":
    "4. 利用者が本人が当社の利用者であることを証明するため、当社に発送した身分証明書のコピー等の書類一切は、本人確認後に直ちに破棄します。",
  "5. 법정대리인의 동의여부를 증빙하는 자료는 접수일로부터 최소 2년간 보유함을 원칙으로 하나, 법정대리인 동의 이후 2년 이내에 이용자가 회원탈퇴를 하는 경우에는 그 즉시 파기합니다.":
    "5. 法定代理人の同意の有無を証明する資料は、受付日から最小２年間保有することを原則としていますが、法定代理人の同意後２年以内に利用者が会員を退会した場合は、これを直ちに破棄します。",
  "6. 『상법』 및 『전자상거래 등에서의 소비자보호에 관한 법률』등 관련법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 법령에서 정한 일정한 기간 동안 이용자정보를 보관합니다. 이 때 회사는 보관하는 정보를 그 보관 이외의 목적으로 사용할 수 없습니다. 구체적인 사항은 다음과 같습니다.":
    "6. 「商法」及び「電子商取引等における消費者保護に関する法律」など、関連法令の規定により保存する必要がある場合、当社は法令に定められた一定期間、利用者情報を保管します。この際、会社は保管する情報をその保管以外の目的で使用できません。",
  "제 10 조 개인정보 파기절차 및 방법": "第10条 個人情報の破棄の手続き及び方法",
  "회사는 이용자에게 사전에 고지하고 동의 받은 개인정보의 수집ㆍ이용목적이 달성되면 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.":
    "当社は、利用者に事前に告知し、同意を得た個人情報の収集・利用目的が完了した場合、遅滞なくこれを破棄します。破棄の手続き及び方法については、以下のとおりです。",
  "1. 파기절차": "1. 破棄手続き",
  "(1) 이용자가 입력한 개인정보는 수집ㆍ이용목적이 달성되면 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라":
    "(1) 利用者が入力した個人情報は、収集・利用目的を完了した場合、別途のDBに移され(紙の場合は別途の書類箱)、内部の方針及びその他の関連法令に基づく情報保護事由に従い",
  "(2) 제10조에 근거하여 일정기간 저장 후, 재생할 수 없는 방법으로 파기됩니다.":
    "(2) 第10条に基づいて一定期間保存した後、再生できない方法で破棄されます。",
  "(3) 별도 DB로 옮겨진 이용자의 개인정보는 법령에 의한 경우가 아닌 다른 목적으로 이용되지 않습니다.":
    "(3) 別途DBに移された利用者の個人情報は、法令の定めによるもの以外の目的では利用されません。",
  "2. 파기방법": "2. 破棄方法",
  "(1) 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.":
    "(1) 電子ファイルの形態で保存された個人情報は、記録を再生することができない技術的方法を使用して削除します。",
  "(2) 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.":
    "(2) 紙に印刷された個人情報はシュレッダーまたは焼却を行い破棄します。",
  "제 11 조 개인정보보호를 위한 기술적ㆍ관리적 대책":
    "第11条 個人情報保護のための技術的、管理的対策",
  "1. 이용자의 개인정보는 이용자 자신이 설정한 비밀번호에 의해 우선적으로 보호되고 있습니다. 따라서 이용자는 절대로 비밀번호를 타인에게 알려주거나 공유해선 안되며, 회사 서비스의 이용완료 후에는 반드시 로그아웃(LOG-OUT)을 이용하여 웹 브라우저를 종료하여야 합니다. 특히 다른 사람과 컴퓨터를 공유하여 사용하거나 공공장소에서 이용한 경우에는 타인에게 개인정보가 알려지는 것을 막기 위하여 위 절차는 반드시 필요합니다. 이외에도 회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 및 훼손되지 아니하도록 안전성 확보를 위하여 다음과 같은 기술적 내지 관리적 대책을 강구하고 있습니다.":
    "1. 利用者の個人情報は、利用者本人が設定したパスワードによって優先的に保護されています。したがって、利用者は絶対にパスワードを他人に教えたり共有してはならず、会社のサービス利用完了後には必ずログアウト(LOG-OUT)を利用し、ウェブブラウザを終了しなければなりません。特に、他人とコンピュータを共有して使用したり、公共の場所で利用した場合は、他人に個人情報が知られるのを防ぐため、上記手続きは必ず必要です。この他にも会社は、利用者の個人情報を処理するにあたり、個人情報が紛失、盗難、漏出、変造、毀損されないよう安全性確保のため、次のような技術的管理対策を講じています。",
  "2. 기술적 대책": "2. 技術的対策",
  "(1) 이용자의 비밀번호는 암호화 처리되어 이용자 개개인의 비밀번호를 알 수 없도록 되어 있습니다.":
    "(1) 利用者のパスワードは暗号化処理され、利用者一人ひとりのパスワードを知ることができないようになっています。",
  "(2) 개인정보의 훼손에 대비하여 정보를 수시로 백업하고 있으며 최신의 백신프로그램을 이용하여 이용자의 개인정보와 자료가 누출되거나 손상되지 않도록 하고 있습니다.":
    "(2) 個人情報の毀損に備えて情報を随時バックアップしており、最新のワクチンプログラムを利用して利用者の個人情報と資料が漏出、損傷しないようにしています。",
  "(3) 암호화 통신 등을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.":
    "(3) 暗号化通信などを通じてネットワーク上で個人情報を安全に送信できるようにしています。",
  "(4) 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며 기타 시스템적으로 보완성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.":
    "(4) 侵入遮断システムを利用し、外部からの無断アクセスを統制しており、その他システム的な補完性を確保するための可能なすべての技術的装置を備えるよう努力しています。",
  "3. 관리적 대책": "3.管理的対策",
  "(1) 개인정보보호책임자와 최소한으로 구성된 인원들에 대해서만 개인정보 관련 처리를 한정시키고 있으며 기타 인원에 대해서는 개인정보에 대한 접근권한을 제한하고 있습니다.":
    "(1) 個人情報保護責任者と最小限に構成された者に対してのみ個人情報関連処理を限定し、その他の者に対しては個人情報に対するアクセス権限を制限しています。",
  "(2) 개인정보처리인원에 대한 교육을 통하여 개인정보보호의무 등 관련된 의무를 항상 강조하고 있으며 개인정보처리방침이행사항 및 담당자의 준수 여부를 수시로 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 최선의 노력을 기울이고 있습니다.":
    "(2) 個人情報処理人員に対する教育を行い、個人情報保護義務など関連する義務を常に強調しており、プライバシーポリシー履行事項および担当者の遵守事項を随時確認し、問題が発見された場合は直ちに修正して正すよう最善を尽くしています。",
  "4. 회사는 이용자 스스로의 실수 또는 부주의 기타 기본적인 인터넷의 위험으로 인해 발생되는 개인정보유출 및 훼손에 대해서는 어떠한 책임도 부담하지 않습니다.":
    "4. 当社は、利用者自らの誤り又は不注意、その他基本的なインターネットの危険により発生する個人情報の流出及び毀損については、いかなる責任も負いません。",
  "제 12 조 개인정보보호책임자 및 담당자의 연락처":
    "第12条 個人情報保護責任者及び担当者の連絡先",
  "이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 회사는 개인정보보호책임자를 두고 있습니다. 개인정보와 관련된 문의사항이 있으시면 아래의 개인정보보호책임자에게 연락주시기 바랍니다. 문의하신 사항에 대해서 신속하고 성실하게 답변해드리겠습니다":
    "利用者の個人情報を保護し、個人情報に関する苦情を処理するために当社では個人情報保護責任者を置いています。個人情報に関するお問い合わせは、以下の個人情報保護責任者までご連絡ください。ご質問された点に対して、迅速かつ誠実にお答えいたします。",
  "[개인정보보호 책임자]": "[個人情報保護責任者]",
  "이름 : 김재우": "氏名：金載祐",
  "소속 : ㈜팡스카이 이사": "所属：(株)Pangsky 理事",
  "[개인정보관리 담당부서]": "[個人情報管理担当部署]",
  "부서 : 서비스운영팀": "部署：サービス運営チーム",
  "전화번호 : 070-4658-9412": "電話番号：070-4658-9412",
  "제 13 조 청소년보호를 위한 활동": " ",
  "회사는 각종 청소년 유해정보로부터 청소년을 보호하고자 관련법에 따라 만 19세미만의 청소년이 유해정보에 접근할 수 없도록 청소년보호정책을 마련하여 시행하고 있습니다. 또 회사는 청소년의 건전한 성장을 저해하는 음란, 불법 유해정보와 비윤리적, 반사회적 행위에 대해서는 엄격하게 제재하기 위하여 다음과 같이 활동하고 있습니다.":
    " ",
  "1. 청소년유해정보로부터의 청소년보호계획의 수립": " ",
  "2. 청소년유해정보에 대한 청소년 접근제한 및 관리조치": " ",
  "3. 정보통신업무 종사자에 대한 청소년유해정보로부터의 청소년보호를 위한 교육":
    " ",
  "4. 청소년유해정보로 인한 피해상담 및 고충처리": " ",
  "5. 그 밖에 청소년유해정보로부터 청소년을 보호하기 위하여 필요한 사항": " ",
  "제 14 조 개인정보 관련 신고 및 분쟁조정": " ",
  "개인정보에 대한 상담이 필요한 경우에는 다음의 곳으로 문의하시길 바랍니다.":
    " ",
  "- 개인정보침해신고센터 전화: 1336, 홈페이지: www.cyberprivacy.or.kr": " ",
  "- 개인정보분쟁조정위원회 전화: 02-405-4747, 홈페이지: www.kopico.or.kr": " ",
  "- 경찰청 사이버테러대응센터 홈페이지: www.ctrc.go.kr": " ",
  "제 15 조 정책 변경에 따른 공지의무": "第13条 本ポリシーの改定",
  "법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 시에는 변경되는 개인정보처리방침을 시행하기 최소 7일전에 서비스 초기화면을 통해 변경이유 및 내용 등을 공지하도록 하겠습니다.":
    "法令またはセキュリティ技術の変更によって内容の追加、削除及び修正がある場合には、変更されるプライバシーポリシーを施行する最低7日前にわかりやすい方法にて内容を告知いたします。",
  부칙: "付則",
  "본 개인정보처리방침은 2017년12월07일부터 시행됩니다.":
    "本ポリシーは、2021年04月05日から施行されます。",
  "2016년 05월 09일부터 시행되던 종전의 정책은 본 정책으로 대체합니다.": " ",
  "안녕하세요. 팡스카이입니다.": "こんにちは、Pangskyです。",
  "팡스카이는 게임 퍼블리싱 서비스를 기반으로":
    "Pangskyは、ゲームパブリッシング事業を基盤",
  "한국을 포함한 전 세계에 특별한 재미와 경험을 제공하는 기업입니다.":
    "に全世界に特別な楽しさと経験を提供する企業です。",
  "창의적인 상상력을 바탕으로 온라인게임과 모바일게임, ":
    "クリエイティブな想像力を基に、オンラインゲーム、モバイルゲーム、",
  "그리고 콘텐츠 사업으로 영역을 확장하고 있으며,":
    "そして、コンテンツ事業に領域を拡張しており、",
  "새로운 도전을 통해 글로벌 고객에게 즐거움을 선사할 것입니다.":
    "新しい挑戦を通じて全世界のお客様に楽しさをお届けします。",
  "게임퍼블리싱을 위한": "ゲームパブリッシングのための",
  "해외 네트워크": "海外ネットワーク",
  "중국의 바이두를 비롯하여 약 40여 게임관련":
    "中国のバイドゥ(Baidu)をはじめ、約40社のゲームに関する",
  "기업들과 긴밀한 협력 네트워크를 구축 완료":
    "企業と緊密な協力ネットワーク構築完了",
  "게임 확보 여력": "ゲームの確保",
  "해외에서 성공 케이스를 가진": "海外で成功例を持つ",
  "중국 개발 게임들에 관한 판권 확보 가능":
    "中国開発のゲームに関する版権を確保可能",
  "국내 네트워크": "国内ネットワーク",
  "팡스카이의 창업 멤버들은 한국 게임 업계 평균 19년":
    "Pangskyの創業メンバーは、韓国のゲーム業界平均19年の",
  "경력을 가지고 있으며, 다양한 인맥 및 리소스 네트워크를 보유":
    "経歴を持ち、様々な人脈やリソースネットワークを保有",
  "안정적인 운영시스템": "安定したな運営システム",
  "게임 퍼블리싱 업무 진행 경험을 바탕으로":
    "ゲームパブリッシング業務の経験をもとに、",
  "뛰어난 리스크 관리 및 빠른 업무처리 및":
    "優れたリスク管理および迅速な業務処理および",
  "Big Data 연구소를 보유하여 체계적 마케팅 가능":
    "ビックデータ研究所を保有し、体系的なマーケティングが可能",
  " ‘묵혼’ 태국 출시": "「墨魂」タイ発売",
  " ‘RF 온라인’ 일본 서비스": "「RF Online」日本サービス",
  " ‘포트리스 배틀로얄‘ 서비스 출시":
    "「フォートレスバトルロイヤル」サービス開始",
  "‘묵혼’ 온라인 대만 출시": "「墨魂」オンライン 台湾発売",
  " ‘고수’ 대만 출시": "「高手」 台湾発売",
  " ‘묵혼’ 온라인 한국 출시": "「墨魂」オンライン 韓国発売",
  "‘고수‘ 모바일 한국 출시": "「高手」モバイル 韓国発売",
  " 온라인 SF MMORPG ‘RF온라인’ 서비스 출시":
    "オンラインSF MMORPG「RF Online」韓国でサービス開始",
  " 씨씨알컨텐츠트리와 ‘포트리스 2M’ 퍼블리싱 계약 체결":
    "韓国 CCR INCと「ポトリス 2M」パブリッシング契約締結",
  " 모바일 MMORPG ‘무협왕’ 앱 스토어 출시":
    "モバイルMMORPG「武侠王」AppStore(韓国)発売",
  " 모바일 MMORPG ‘리그너스 대륙전기‘ 구글 플레이, 원 스토어 출시":
    "モバイルMMORPG 「リグナス大陸戦記」Google play(韓国)、One Store(韓国)発売",
  "모바일 SRPG ‘삼국 어웨이큰＇구글 플레이, 원 스토어 출시":
    "モバイルSRPG「三國AWAKEN」Google play(韓国)、One Store(韓国)発売",
  " 모바일 MMORPG ‘십만대적검 : 리부트‘출시":
    "モバイルMMORPG「十万対敵剣 : Reboot」韓国発売",
  " 모바일 MMORPG ‘백가쟁패’ 구글 플레이, 원 스토어 출시":
    "モバイルMMORPG「百家争覇」Google play(韓国)、One Store(韓国)発売",
  "모바일 MMORPG ‘신의 아이들‘구글 플레이, 앱 스토어 ,원 스토어 출시":
    "モバイルMMORPG「神の子供」Google play(韓国)、AppStore(韓国)、One Store(韓国)発売",
  " 모바일 MMORPG ‘십만대적검’ 구글 플레이, 원 스토어 출시":
    "モバイルMMORPG「十万対敵剣」Google play(韓国)、One Store(韓国)発売",
  " 모바일 MMORPG ‘여신전기‘ 구글 플레이, 원 스토어 출시":
    "モバイルMMORPG「女神戦記」Google play(韓国)、One Store(韓国)発売",
  " 모바일 무협 MMORPG ‘군도‘ 구글 플레이, 원 스토어 출시":
    "モバイル武侠MMORPG「君到」Google play(韓国)、One Store(韓国)発売",
  " 웹 MMORPG ‘촉산협객전‘ 서비스 출시":
    "PC用MMORPG「蜀山侠客伝」韓国でサービス開始",
  " 블루사이드와 ‘킹덤언더파이어‘ 모바일 게임 개발 업무 협약 체결":
    "ブルーサイドと「キングダムアンダーファイア」モバイルゲーム開発業務協約締結",
  " 모바일 게임 ‘형님 모바일’ 구글 플레이 무협모바일게임 장르 1위 달성":
    "モバイルゲーム「アニキモバイル」Google play(韓国)の武侠モバイルゲームジャンル1位を達成",
  " 웹 무협 MMORPG ‘검우강호’ 사전 등록자 15만명 기록":
    "PC用武侠MMORPG「剣雨江湖」事前登録者15万人を記録",
  " 웹게임 ‘무쌍 온라인‘ 서비스 출시":
    "PCゲーム「無双オンライン」韓国でサービス開始",
  "㈜팡게임, ‘㈜팡스카이’로 사명 변경": "(株)Panggame、(株)Panskyに社名変更",
  " 모바일 해적 전략시물레이션 게임 ‘무적함대1942’ 구글 플레이 출시":
    "モバイル海賊戦略シミュレーションゲーム「無敵艦隊1942」Google play(韓国)発売",
  " 모바일 MMOARPG ‘칼에 깃든 바람‘ 원 스토어 출시":
    "モバイルMMOARPG「刀に宿る風」One Store(韓国)発売",
  " 모바일게임 ‘대황제M’ 구글 플레이,원 스토어 출시, 구글 플레이 전략게임 1위 달성":
    "モバイルゲーム「大皇帝M」Google play、One Store(韓国)発売、Google play戦略ゲーム1位達成",
  " 무협 웹게임 ‘운중가온라인‘ 서비스 오픈":
    "PC武侠ゲーム「雲中歌オンライン」サービスオープン",
  " PlamJoy와 웹게임 ‘운중가온라인’ 한국 퍼블리싱 계약 체결":
    "PlamJoyとPCゲーム「雲中歌オンライン」で韓国パブリッシング契約を締結",
  " PC온라인게임 ‘대황제’ 네이버 등 8개사 채널링 계약":
    "PCオンラインゲーム「大皇帝」、NAVERなど8社によるチャネリング契約",
  "모바일게임 ‘바다속이야기’ 구글 플레이, N스토어 출시":
    "モバイルゲーム「海の中の物語」Google play(韓国)、Nストア(韓国)発売",
  " PC온라인게임 ‘데빌에이지‘ 서비스 오픈":
    "PCオンラインゲーム「デビルエイジ」韓国でサービスオープン",
  " 벤처기업확인(벤처기업협회)": "ベンチャー企業確認(ベンチャー企業協会)",
  " PC온라인게임 “뉴던전히어로” 오픈":
    "PCオンラインゲーム「ニューダンジョンヒーロー」を国でオープン",
  " ‘기업부설연구소 설립(미래창조과학부)":
    "「企業付設研究所設立(未来創造科学部)",
  " 중국 최대 검색포털 ‘바이두‘의 “바이두모바일게임＂과 협력체결":
    "中国最大の検索ポータル「百度」の「百度モバイルゲーム」と協力締結",
  "모바일 게임 “구몽:전생의기억“  구글 플레이 출시":
    "モバイルゲーム「グモン：前世の記憶」Google play(韓国)発売開始",
  " 모바일 게임 “케로로 액션히어로 for Kakao 구글 플레이, 앱 스토어 ,원 스토어 출시":
    "モバイルゲーム「ケロロアクションヒーロー」 for Kakao Google Play(韓国)、App Store(韓国)、One Store(韓国)発売",
  " 국내 모바일게임사 서비스팀 인수":
    "韓国のモバイルゲーム会社のサービスチームを買収",
  " PC온라인게임 “구몽온라인“ 6개 채널링 동시오픈":
    "PCオンラインゲーム「グモンオンライン」で6つのチャネリング同時オープン",
  " 중국 ‘광주명조네트워크’와 PC온라인게임 “구몽온라인“ 한국 독점 서비스 계약체결":
    "中国「MINGCHAO NETWORK」とPCオンラインゲーム「グモンオンライン」を韓国独占サービス契約締結",
  " ‘㈜투윈게임즈’와 PC온라인게임 “원더킹“ 채널링 계약 체결":
    "「(株)2win games」とPCオンラインゲーム「ワンダーキング」のチャネリング契約締結",
  " ‘㈜이야소프트’와 PC온라인게임 “에다전설“ 국내 서비스 계약 체결":
    "「㈱eyasoft」とPCオンラインゲーム「Legend of EddA」の韓国サービス契約を締結",
  " 중국 ‘자모조이’와 모바일게임 “스와트앤좀비” 서비스 계약 체결":
    "中国「Jamo Games」とモバイルゲーム「SWAT and Zombies」サービス契約締結",
  "‘㈜미디어로그’와 “스와트앤좀비“ 해외 서비스 계약 체결":
    "「(株)medialog」と「SWAT and Zombies」海外サービス契約締結",
  " www.panggame.com 사이트 오픈": "www.panggame.com サイトオープン",
  " 주식회사 팡 게임 설립": "株式会社Panggame設立",
  "‘㈜더파이널파이브’와 PC온라인게임 ‘’와인드업‘ 서비스 계약 체결":
    "「(株)The Final Five」とPCオンラインゲーム「Wind Up」サービス契約締結",
  "팡스카이가 걸어온 길": "Pangskyの歩んできた道",
  "전화 : ": "TEL：",
  "팩스 : ": "Fax：",
  "서울특별시 강남구 논현로80길 25":
    "20, Digital-ro 31-gil, Guro-gu, Seoul, Republic of Korea",
  "팡스카이와 함께 꿈을 만들어 보실래요?":
    "Pangskyと共に夢を作ってみませんか？",
  "지원하러 가기": "応募する",
  "No tie! 편안한 복장": "No tie! 楽な服装で",
  "자율 복장으로 출퇴근길을 가볍게~!": "自由な服装で通勤できます！",
  "사내 카페테리아 운영": "社内カフェテリア運営",
  "카페테리아, 미니 피트니스 등 휴게 공간":
    "カフェテリア、ミニフィットネスなどの休憩スペース",
  "해피 급여 데이": "ハッピー給与DAY",
  "급여일에 한해 점심시간 30분 연장": "給料日に限り昼食時間を30分延長",
  "명절 선물 지급": "名節にプレゼント支給",
  "설,추석 명절에 백화점 상품권 지급": "秋夕、旧正月に百貨店の商品券支給",
  "자율 연차제": "自由な有給使用",
  "근로기준법에 따른 연차 제도 실시": "労働基準法に基づく有給制度実施",
  "시차 출퇴근제": "時差通勤制度",
  "출근10:00 ~ 10:30 | 퇴근 19:00 ~ 19:30": "出勤10:00~10:30 | 退勤19:00~19:30",
  "야간 근무자 지원": "夜間勤務者支援",
  "야근 식대, 야근 택시비": "食事代、タクシー代",
  "각종 경조사 지원": "各種慶弔事支援",
  "경조금 및 경조 휴가": "慶弔金および慶弔休暇",
  패밀리데이: "ファミリーデー",
  "매월 셋째주 금요일 17시 조기 퇴근": "毎月第3金曜日は17時退勤",
  "인재 추천 보상금": "人材推薦の報酬金",
  "추천 인재 정규직 전환 시 추천자에게 직급에 따른 보상 지급":
    "推薦人材が正社員に転換した場合、推薦者の役職に応じて報酬を支給",
  "회식비 지원": "会食費支援",
  "팀원 단합을 위한 월 단위 회식비 지원":
    "チーム員の団結のために月単位で会食費を支援",
  "생일 축하 및 선물 지급": "誕生日祝いのプレゼント支給",
  "백화점 상품권 및 유급 휴가 0.5일": "百貨店の商品券および有給休暇0.5日",
  소개: "紹介",
  팡스카이소개: "PANGSKY 紹介",
  게임: "ゲーム",
  미디어: "メディア",
  보도자료: "報道資料",
  "미디어 행사": "メディア行事",
  문화: "文化",
  기업문화: "企業文化",
  복지: "福祉",
  "팡스카이 소식": "PANGSKY NEWS",
  채용: "採用",
  "채용 소개": "採用紹介",
  투자정보: "投資情報",
  기업지배구조: "コーポレートガバナンス",
  "광고/제휴 문의": "広告/提携に関するお問い合わせ",
  "기업윤리 신고센터": "企業倫理申告センター",
  "개인정보 처리방침": "プライバシーポリシー",
  개인정보처리방침: "プライバシーポリシー",
  "팡스카이 스토어": "Pangsky Store",
  "게임 고객센터": "カスタマーセンター",
  "광고/제휴 문의 안내": "広告/提携に関するお問い合わせ案内",
  "팡 스카이는 보다 더 투명하고": "Pangskyはより透明で",
  "윤리적인 기업문화의 정착을 위해 기업 윤리 신고센터를 운영합니다.":
    "倫理的な企業文化を定着させるため、企業倫理申告センターを運営しています。",
  "임직원의 불공정한 업무수행,금품,접대 등 부당한 요구 및 업무 중 부당한 회계처리":
    "役職員の不公正な業務遂行、金品、接待など不当な要求及び業務中の不当な会計処理",
  "기타 부정,비리 행위를 상담, 신고 할 수 있습니다.":
    "その他の不正行為を相談・申告することができます。",
  "임직원,협력사, 기타 제보와 관련있는 거래 당사자, 제 3자 등":
    "役職員、協力会社その他の情報提供に係る取引当事者、第三者等",
  "그 누구라도 실명 또는 익명으로 신고 가능하며, 신고된 내용은":
    "実名または匿名で申告することが可能で、申告された内容は",
  "팡스카이 감사 주관하에 공정하고 엄중하게 조사 처리 됩니다.":
    "内部監査が公正かつ厳重に調査を行います。",
  "제보자의 신분과 상담,신고 내용 등은 철저히 비밀로 보장되며,":
    "情報提供者の身分と相談、申告内容などは、公開されることのないよう慎重に取り扱われ、",
  "제보자는 어떠한 경우에도 정당한 제보를 이유로":
    "情報提供者は、いかなる場合においても正当な情報提供を理由に",
  "불이익이나 처벌을 받지 않음을 약속 드립니다.":
    "不利益や処罰を受けないことをお約束いたします。",
  "접수 시 빠른 시일 내에 처리 할 수 있도록 최선을 다하겠습니다.":
    "情報が提供された際にはなるべく早く処理できるよう最善を尽くします。",
  "상담·신고하기": "相談・申告する",
  "처리결과 확인하기": "処理結果を確認する",
  "게임 고객센터: panggamehelp@panggame.com":
    "ゲームカスタマーセンター：panggamehelp@panggame.com",
  "기업소식": "企業関連",
  "공고": "ニュース"
};
