<template>
  <div id="headersecond">
    <div class="menu-box" @mouseleave="unshowDetail" @mouseover="showDetail">
      <div class="line hidden-xs"></div>
      <!-- pc端导航 -->
      <!-- PC-1 -->
      <el-row class="header-nav hidden-xs">
        <el-col :span="2">
          <div class="header-nav-logo">
            <router-link :to="{ path: '/home' }">
              <button type="button"></button>
            </router-link>
          </div>
        </el-col>
        <el-col class="container navi_body" :span="20">
          <div class="navi_head">
            <ul>
              <li
                v-for="(item, index) in navList"
                :key="index"
                :class="index == navIndex ? 'active' : ''"
                @click="navClick(index, $t(item.name))"
              >
                <div class="navi_title">
                  <router-link :to="item.path">
                    {{ $t(item.name) }}
                    <span v-if="item.children.length > 0"></span>
                    <i class="underline"></i>
                  </router-link>
                </div>
                <p v-if="item.children.length > 0">
                  <span v-for="(i, n) in item.children" :key="n">
                    <a
                      v-if="i.path.substring(0, 4) == 'http'"
                      target="_blank"
                      :href="i.item[`url_${$i18n.locale}`]"
                      >{{
                        i["name_" + $i18n.locale]
                          ? i["name_" + $i18n.locale]
                          : $t(i.name)
                      }}</a
                    >
                    <router-link v-else :to="i.path">{{
                      i["name_" + $i18n.locale]
                        ? i["name_" + $i18n.locale]
                        : $t(i.name)
                    }}</router-link>
                  </span>
                </p>
              </li>
            </ul>
          </div>
        </el-col>
        <el-col :span="2">
          <div
            class="header-changesecond"
            @mouseover="showLang2"
            @mouseleave="hideLang"
          >
            <button></button>
            <ul v-if="langShow">
              <li
                v-for="(item, index) in langTab"
                :key="index"
                @click="langClick(index)"
                :class="[langidx == index ? 'secondactive' : 'second-inactive']"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
      <!-- PC-1end -->

      <!-- 移动端导航导航图标 -->
      <div class="H5_item" v-if="!fullShow" :style="sliderStyle">
        <div @click="make_menu" style="width:32%" class="H5-menu">
          <button type="button">
            <span class="nav-line top"></span>
            <span class="nav-line center"></span>
            <span class="nav-line bottom"></span>
          </button>
        </div>
        <div class="logo" style="width:32%">
          <router-link :to="{ path: 'home' }">
            <button :style="style1" type="button"></button>
          </router-link>
        </div>
        <div style="width:32%" class="lang-change">
          <button @click="showLang" type="button"></button>
          <ul v-if="langShow">
            <li
              v-for="(item, index) in langTab"
              :key="index"
              @click="langClick(index)"
              :class="[langidx == index ? 'secondactive' : 'second-inactive']"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 移动端导航 -->
    <transition name="toggle-cart">
      <ul
        :style="sliderStyle"
        class="H5-menu-item"
        v-show="!fullShow && !dismenu"
      >
        <li v-for="(item, index) in navList" :key="index">
          <div class="mbtitle">
            <router-link :to="item.path">
              {{ $t(item.name) }}
            </router-link>
            <span @click="showToggle(item)"
              ><img src="../assets/img/down.png" alt=""
            /></span>
          </div>
          <ul v-if="item.isSubshow">
            <li @click="closemenu" v-if="item.children.length > 0">
              <h3
                style="margin:10px 0 20px 20px;font-size:16px;"
                v-for="(i, n) in item.children"
                :key="n"
              >
                <a
                  style="color:rgb(122, 122, 122) !important;"
                  v-if="i.path.substring(0, 4) == 'http'"
                  target="_blank"
                  :href="i.item[`url_${$i18n.locale}`]"
                  >{{
                    i["name_" + $i18n.locale]
                      ? i["name_" + $i18n.locale]
                      : $t(i.name)
                  }}</a
                >
                <router-link v-else style="color:#7a7a7a;" :to="i.path">{{
                  i["name_" + $i18n.locale]
                    ? i["name_" + $i18n.locale]
                    : $t(i.name)
                }}</router-link>
              </h3>
            </li>
          </ul>
        </li>
      </ul>
    </transition>
    <div class="mb-menu" :style="style"></div>
  </div>
</template>

<script>
import request from "../api/request";
export default {
  name: "headersecond",
  data() {
    return {
      pagetype: "index",
      fullWidth: 0,
      fullShow: true,
      dismenu: true,
      isShow: true,
      menuShow: true,
      isActive: true,
      langShow: false,
      style: {},
      style1: {},
      headstyle: {},
      sliderStyle: {
        width: ""
      },
      opacity: 0,
      navIndex: sessionStorage.getItem("navIndex")
        ? sessionStorage.getItem("navIndex")
        : 0,
      menuName: "소개",
      langTab: ["한국어", "English", "日本語", "中文（繁體）"],
      langMappings: {
        "한국어": "ko",
        "English": "en",
        "日本語": "jp",
        "中文（繁體）": "tw"
      },
      langidx: 0,
      navList: [
        {
          name: "소개",
          path: "/home",
          isSubshow: false,
          children: [
            {
              name: "팡스카이소개",
              path: "/Introduction"
            }
          ]
        },
        {
          name: "게임",
          path: "/home",
          isSubshow: false,
          children: []
        },
        {
          name: "기업소식",
          path: "/news",
          isSubshow: false,
          children: [
            {
              name: "보도자료",
              path: "/news"
            },
            {
              name: "공고",
              path: "/mediaactivity"
            }
          ]
        },
        {
          name: "문화",
          path: "/culture",
          isSubshow: false,
          children: [
            {
              name: "기업문화",
              path: "/culture"
            },
            {
              name: "복지",
              path: "/welfare"
            }
            // {
            //   name:"팡스카이 소식",
            //   path: "/enterprisenews",
            // }
          ]
        },
        {
          name: "채용",
          path: "/recruitment",
          isSubshow: false,
          children: [
            {
              name: "채용 소개",
              path: "/recruitment"
            }
          ]
        }
        // {
        //   name: "투자정보",
        //   path: "/message",
        //   isSubshow:false,
        //   children: [
        //     {
        //       name: "기업지배구조",
        //       path: "/message",
        //     },
        //   ]
        // },
      ]
    };
  },
  methods: {
    make_menu() {
      //点击导航图标
      this.dismenu = !this.dismenu;
    },
    langClick(index) {
      //语言
      this.langidx = index;
      this.changeLang();
    },
    showLang() {
      //语言
      this.langShow = !this.langShow;
    },
    showLang2() {
      //语言
      this.langShow = true;
    },
    hideLang() {
      this.langShow = false;
    },
    handleResize(event) {
      this.fullWidth = document.documentElement.clientWidth;
      this.sliderStyle.width = this.fullWidth + "px";
      if (this.fullWidth < 767) {
        this.fullShow = false;
      } else {
        this.fullShow = true;
      }
    },
    navClick(index, name) {
      this.navIndex = index;
      sessionStorage.setItem("navIndex", index);
      this.menuName = name;
    },
    showDetail() {
      this.isShow = false;
    },
    unshowDetail() {
      this.isShow = false;
    },
    showToggle: function(item) {
      item.isSubshow = !item.isSubshow;
    },
    // 语言切换
    changeLang() {
      let lang = this.langTab[this.langidx];
      let locale = this.langMappings[lang];
      window.localStorage.setItem("locale", locale);
      this.$i18n.locale = locale;
      this.$forceUpdate();
      this.toActive(locale);
    },
    toActive(lang) {
      this.isActive = lang == "ko" ? true : false;
    },
    closemenu() {
      this.dismenu = !this.dismenu;
    },
    getMenu() {
      let _this = this;
      request
        .get("/banners", {
          banner_type__in: "10",
          limit: "7"
        })
        .then(function(res) {
          if (res.status == 200) {
            let data = res.data.results;
            let result = data.map(item => {
              let res = {
                name: item[`title_${_this.$i18n.locale}`],
                path: item[`url_${_this.$i18n.locale}`],
                item: item
              };
              for (let i in _this.$i18n.availableLocales) {
                let locale = _this.$i18n.availableLocales[i];
                res["name_" + locale] = item["title_" + locale];
              }
              return res;
            });
            _this.navList[1].children = result;
          }
        });
    },
    getCurrentLang() {
      for (let i in this.langTab) {
        if (this.langMappings[this.langTab[i]] == this.$i18n.locale) {
          this.langClick(i);
          break;
        }
      }
    }
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.windowScroll); //监听页面滚动
    this.handleResize();
    this.getMenu();
    this.getCurrentLang();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>

<style lang="scss">
#headersecond {
  overflow: hidden;
  height: 60px;
  transition: height ease 0.3s;
  background: #fff;
}
.header-changesecond button {
  background: url("../assets/img/m_icn_lang.svg");
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  border: none;
  width: 25px;
  height: 25px;
  outline: none;
  margin-top: 20px;
}
.header-changesecond ul li {
  height: 30px;
  line-height: 30px;
}
@media only screen and (max-width: 767px) {
  #headersecond {
    height: 40px;
    background: transparent;
  }
  .menu-box {
    height: 40px;
    background-color: #fff;
  }
}
#headersecond .header-nav {
  padding: 0 60px;
}
#headersecond:hover {
  height: 300px;
}
#headersecond .navi_head {
  height: 60px;
}
#headersecond .navi_head > ul > li {
  width: 200px;
  text-align: center;
  height: 300px;
  display: inline-block;
  font-weight: bold;
  color: #000;
  font-size: 14px;
  vertical-align: top;
}
#headersecond .navi_head > ul > li > p {
  margin-top: 20px;
  padding-left: 30px;
}
#headersecond .navi_head > ul > li > p span a {
  color: #969696;
  text-decoration: none;
  display: block;
  width: 150px;
  margin: 10px 0;
}
#headersecond .navi_head > ul > li > p span a:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
#headersecond .navi_title {
  font-size: 16px;
  line-height: 50px;
  margin-top: 8px;
}
#headersecond .navi_title a {
  text-decoration: none;
  background-image: linear-gradient(black, black);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 0 3px;
  transition: background-size 100ms ease-in-out;
  padding-bottom: 20px;
  font-weight: bolder;
  color: #000;
}
#headersecond .navi_title a:hover {
  background-size: 100% 3px;
}
/* 顶部 */
#headersecond {
  position: fixed;
  z-index: 999;
  color: #000;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
}
#headersecond .header-top {
  height: 50px;
  color: #000;
  font-size: 12px;
  line-height: 50px;
  background: #474747;
}
/* 导航栏 */
#headersecond .header-nav {
  height: 300px;
  display: flex;
}
/* 导航栏logo */
#headersecond .header-nav .header-nav-logo {
  height: 60px;
  position: relative;
}
/* 导航栏logo图片 */
#headersecond .header-nav .header-nav-logo button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: url("../assets/img/logo_black.png");
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  border: none;
  height: 18px;
  outline: none;
  width: 120px;
}
/* 导航栏 语言切换 */
#headersecond .header-nav .header-changesecond {
  text-align: left;
  line-height: 60px;
  color: #999;
  cursor: pointer;
  height: 60px;
}
#headersecond .header-changesecond .lang {
  display: flex;
}
#headersecond .header-changesecond .lang li {
  font-weight: bolder;
  position: relative;
}
#headersecond .header-changesecond .lang li + li {
  margin-left: 20px;
  padding-left: 20px;
}
#headersecond .header-changesecond .lang li + li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  width: 1px;
  height: 12px;
  background-color: #999;
  transform: translate3d(0, -6px, 0);
}

@media only screen and (max-width: 767px) {
  .secondactive {
    color: #999;
  }
  .second-inactive {
    color: #000;
  }
  #headersecond .header-nav .header-changesecond {
    color: #000;
  }
}
#headersecond .menu-box {
  position: relative;
}
#headersecond .menu-box .line {
  height: 1px;
  background: rgb(204, 204, 204);
  position: absolute;
  top: 60px;
  width: 100%;
}
@media screen and (max-width: 767px) {
}
.Topnav .Topnav_item {
  position: fixed;
  display: flex;
  top: 0;
  z-index: 1999;
  left: 0;
  color: #fff;
  width: 100%;
}
.H5_item {
  position: absolute;
  top: 10px;
  display: flex;
  width: 100%;
  padding: 0 10px;
}
.H5_item .logo button {
  background: url("../assets/img/logo_black.png");
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  border: none;
  width: 10.75rem;
  height: 1.55rem;
  outline: none;
}
.mb-menu {
  height: 40px;
}
.H5_item div:nth-child(1) img {
  width: 25px;
}
.H5_item div:nth-child(2) {
  text-align: center;
}
.H5_item .lang-change {
  text-align: right;
  position: relative;
  color: #333;
  z-index: 999;
}
.H5_item .lang-change button {
  background: url("../assets/img/m_icn_lang.svg");
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  border: none;
  width: 25px;
  height: 25px;
  outline: none;
}
.H5_item .lang-change ul {
  position: absolute;
  top: 30px;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  width: 60px;
  right: -18px;
}
.H5_item .lang-change ul li {
  height: auto;
  text-align: center;
  padding: 5px 0;
  margin: 5px 0;
}
.H5-menu {
  width: 2rem;
  height: 1.492rem;
  button {
    position: relative;
    display: block;
    width: 2rem;
    height: 1.492rem;
    border: 0;
    background: none;
    outline: 0;
    .top {
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background: #000;
      top: 0.166rem;
    }
    .center {
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background: #000;
      top: 0.766rem;
    }
    .bottom {
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background: #000;
      top: 1.366rem;
    }
  }
}
.H5-menu-item {
  position: fixed;
  z-index: 998;
  top: 40px;
  background-color: #fff;
  li {
    margin-top: 10px;
    line-height: 42px;
    text-align: left;
    border-bottom: 1px solid #969696;
    a {
      color: #000;
      text-decoration: none;
    }
    .mbtitle {
      height: 50px;
      line-height: 50px;
      text-indent: 20px;
      margin: 0;
      color: #000;
      font-size: 18px;
      position: relative;
      span {
        position: absolute;
        right: 10px;
        img {
          width: 40px;
        }
      }
      ul {
        li {
          h3 {
            a {
              font-size: 14px;
              margin-left: 20px !important;
            }
          }
        }
      }
    }
  }
}
.flex_box {
  display: flex;
  align-items: center;
}
.toggle-cart-enter-active,
.toggle-cart-leave-active {
  transition: all 0.4s ease-out;
}
.toggle-cart-enter,
.toggle-cart-leave-active {
  transform: translateY(-100%);
}
</style>
