export const m = {
  "검색어를 입력해주세요": "Please enter a keyword to search.",
  "제 1 조 총칙": "Article 1 General Rules",
  "1. ㈜팡스카이(이하 ‘회사’라 합니다.)는 서비스를 제공하기 위해 필요한 필수항목을 이용자의 동의 하에 수집하고 있습니다. 개인정보란 생존하는 개인에 관한 정보로서 해당 정보에 포함되어 있는 성명 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보를 말합니다.":
    '1. Pangsky Co., Ltd. (hereinafter referred to as the "Company") collects essential items necessary to provide services with the consent of the users. Personal information refers to information about an individual that can be identified by the name, etc. contained in the information.',
  "2. 회사는 이용자의 개인정보를 소중히 처리하며, 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 방송통신위원회가 제정한 『개인정보보호지침』을 준수하고 있습니다.":
    "2. The Company treats users' personal information carefully and complies with the Communications Secret Protection Act, the Telecommunications Business Act, the Act on Promotion of Information and Communication Network Utilization and Information Protection, and the 『Privacy Guidelines』 established by the Korea Communications Commission.",
  "3. 회사는 관련 법령에 의거하여 개인정보처리방침을 통해 이용자의 권익 보호를 위해 최선을 다하고 있습니다.":
    "3. The company is committed to protecting the rights and interests of users through personal information processing policies in accordance with the relevant laws and regulations.",
  "4. 회사는 개인정보처리방침을 홈페이지 첫 화면 하단에 공개함으로써 이용자가 언제나 용이하게 보실 수 있도록 조치하고 있습니다.":
    "4. The company discloses the personal information processing policy at the bottom of the first screen of the homepage so that users can always easily view it.",
  "제 2 조 수집하는 개인정보 항목 및 수집방법":
    "Article 2 Items and Methods of Collecting Personal Information;",
  "1. 회사는 회원가입 시 서비스 제공을 위해 필요한 최소한의 개인정보만을 수집합니다.":
    "1. When registering as a member, the company collects the minimum amount of personal information needed to provide the service.",
  "4. 회사는 설문조사, 경품발송 등을 위한 목적으로 이용자의 개인정보 기재를 요청하거나 보다 나은 서비스를 위하여 소프트웨어 정보를 추출할 수 있으며, 수집한 목적 등이 완료된 경우에는 관계법령에서 정한 경우를 제외하고 수집된 개인정보가 열람 또는 이용될 수 없도록 처리합니다.":
    "4. The company may request user's personal information for surveys, prizes, etc. or extract software information for better service. If the purpose of collection is completed, the collected personal information shall not be read or used except as prescribed by relevant statutes.",
  "5. 유료서비스를 이용하고자 하는 이용자는 본인의 이용료결제에 필요한 범위내에서 은행계좌정보, 신용카드정보 등의 항목을 입력해야 하며 이는 이용료 결제를 위한 목적으로 사용됩니다.":
    "5. Users who want to use the paid service must enter bank account information, credit card information, etc. within the scope necessary for their payment of fees, which is used for the purpose of payment of fees.",
  "6. 회사는 서비스의 불량이용자 규제를 위해 IP주소, 쿠키정보, 불량이용기록 등을 수집할 수 있습니다.":
    "6. The company may collect IP addresses, cookie information, and records of non-use in order to regulate bad users in the service.",
  "제 3 조 개인정보 수집 및 이용에 대한 동의":
    "Article 3 Consent to Collection and Use of Personal Information",
  "회사는 이용자의 개인정보 수집 및 이용에 대한 동의를 받고 있습니다. 이용자의 개인정보 수집과 관련하여 회원가입 시 개인정보처리방침에 대한 동의절차를 마련하여, 동의 버튼을 클릭하면 개인정보 수집 및 이용에 대해 동의한 것으로 봅니다. 다만, 아래의 경우에는 개인정보 수집 및 이용에 대한 이용자의 사전 동의를 구하지 않을 수 있습니다.":
    "The company receives consent from users to collect and use personal information. In connection with the user's personal information collection, the consent process for the personal information processing policy is prepared when registering as a member, and if you click the consent button, it is deemed that you have agreed to collect and use personal information. However, in the following cases, prior consent of the user for the collection and use of personal information may not be obtained.",
  "1. 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한 개인정보로서 경제적 기술적인사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우":
    "1. In case it is clearly difficult to obtain ordinary consent due to economic and technical reasons as personal information necessary to fulfill the contract for the provision of information and communication services",
  "2. 정보통신서비스의 제공에 따른 요금정산을 위하여 필요한 경우":
    "2. When it is necessary for the settlement of fees according to the provision of information and communication services",
  "3. 다른 법률에 특별한 규정이 있는 경우":
    "3. When there are special provisions in other laws",
  "제 4 조 개인정보의 수집 및 이용목적":
    "Article 4 Purpose of Collection and Use of Personal Information;",
  "1. 회원 가입 시 수집하는 개인정보 별 수집목적은 다음과 같습니다.":
    "1. The purpose of collecting personal information for each member is as follows.",
  "(1) 이름(실명), 아이디, 비밀번호: 신용평가기관을 통한 실명확인, 중복가입확인, 서비스 이용에 따른 본인식별, 연령제한 서비스, 인구통계학적 분석, 고객센터의 운영, 불량이용자의 부정한 이용재발 방지":
    "(1) Name (real name), ID, password: Real name verification, duplicate subscription verification, identification by service use, age-restricted service, demographic analysis, operation of the customer center, and prevention of fraudulent use by bad users.",
  "(2) 비밀번호 힌트/답: 이용자가 비밀번호를 분실한 경우 이에 고객지원을 위한 본인확인용 질문/답":
    "(2) Password hint/answer: If the user loses his/her password, it is a question/answer for identification for customer support.",
  "(3) 전자우편: 공지사항 전달, 본인 의사확인, 고객문의 답변 등 원활한 의사소통 채널확보, 신규 서비스 나 이벤트 정보 등 최신 정보 등의 안내":
    "(3) E-mail: Delivering announcements, confirming your intentions, securing smooth communication channels such as answering customer inquiries, and providing the latest information such as new services or event information",
  "(4) 거주지 주소: 지역별 이벤트 등의 정보 제공, 지역별 커뮤니티 서비스 제공":
    "(4) Address of residence: Provision of information such as regional events, provision of regional community services",
  "(5) 연락처(전화번호/휴대폰): 비밀번호 분실 시 SMS 통보 및 고객지원을 위한 연락처 확보":
    "(5) Contact (phone number/mobile phone): SMS notification and customer support in case of loss of password",
  "(6) 부모님(법정대리인) 성명 14세 미만 이용자(이하 “아동”이라 합니다)의 개인정보 수집시 법정대리인 동의를 얻기 위한 실명 확인 및 법정 대리인의 아동 개인정보 열람 등을 위한 고객지원, 만18세 미만 청소년의 가입시 법정대리인 동의를 얻기 위한 실명 확인 및 게임물 이용내역의 고지":
    '(6) When collecting personal information of users under 14 years of age (hereinafter referred to as "child"), customer support for obtaining consent from legal representatives, real name verification for legal representatives, and notice of game usage.',
  "(7) 휴대폰 소유정보, 신용카드 소유정보, 본인확인 서류: 가입 시 혹은 기타 개인정보 변경 요청 시 본인확인을 위한 인증수단":
    "(7) Mobile phone ownership information, credit card ownership information, and identification documents: authentication means for identification when subscribing or requesting change of personal information",
  "제 5 조 조 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항":
    "Article 5 Installation, Operation, and Refusal of Automatic Personal Information Collection Device",
  "■ 쿠키란?": "■ What is a cookie?",
  "특정 웹사이트에서 웹서버가 웹브라우저에게 전달하는 작은 텍스트 파일 서비스 개선을 위해 쿠키를 이용하여 이용자의 접속 기록, 이용 형태 등을 파악합니다.":
    "In order to improve the service of a small text file delivered by a web server to a web browser on a specific website, cookies are used to identify the user's access records and usage patterns.",
  "이용자는 브라우저에서 옵션 설정을 통해 쿠키가 수집되지 않도록 거부할 수 있습니다.":
    "Users can refuse to collect cookies by setting options in their browser.",
  " 고급": "Advanced",
  " 쿠키 그 외 브라우저의 경우 브라우저 별 설정 방식에 따릅니다.":
    "Cookies and other browsers are based on browser-specific settings.",
  "■ 웹로그 분석이란?": "■ What is weblog analysis?",
  "웹사이트상에서 이용자의 서비스 이용 형태에 대한 분석":
    "Analysis of the types of service that users use on the website",
  "oogle Analytics와 같이 다양한 외부 웹로그분석 도구를 사용할 수 있으며 Google Analytics의 경우 데이터가 사용되는 것을 거부할 수 있습니다.":
    "There are a variety of external weblog analysis tools available, such as Google Analytics, and Google Analytics can be refused to use your data.",
  "- Google Analytics 차단: https://tools.google.com/dlpage/gaoptout/ 그 외 웹로그분석 도구의 경우 도구 별 거부 방식에 따릅니다.":
    "- Block Google Analytics: https://tools.google.com/dlpage/gaoptout/ Other weblog analysis tools are subject to tool specific rejection.",
  "제 6 조 개인정보의 제공": "Article 6 Provision Of Personal Information",
  "1. 회사는 이용자의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 어떠한 경우에도 [개인정보의 수집목적 및 이용목적]에서 고지한 범위를 넘어 이용자의 개인정보를 이용하거나 타인 또는 타기업/기관에 제공하지 않습니다.":
    "1. The company shall not use or provide personal information to other companies or institutions beyond the scope of notification under [Purpose and Use of Personal Information] except with the consent of the users or the provisions of Acts and subordinate statutes.",
  "2. 이용자의 개인정보를 제공하는 경우에는 개인정보를 제공받는 자, 개인정보를 제공받는 자의 개인정보 이용 목적, 제공하는 개인정보 항목, 개인정보를 제공받는 자의 개인정보 보유 및 이용기간에 대해 개별적으로 인터넷사이트, 전자우편, 서면, 전화 등을 통해 고지한 후 이에 대한 사전 동의를 구합니다.다만, 다음의 경우에는 이용자의 동의 없이 개인정보를 제공할 수 있습니다.":
    "2. In case of providing personal information of users, the purpose of personal information use, personal information items, personal information items, personal information retention and duration of personal information are notified individually through Internet sites, e-mail, written, and telephone.However, personal information may be provided without the user's consent in the following cases:",
  "(1) 서비스 제공에 따른 요금 정산을 위하여 필요한 경우":
    "(1) When it is necessary for the settlement of charges for the provision of services",
  "(2) 통계작성, 학술연구 또는 시장조사를 위하여 개인을 식별할 수 없는 형태로 제공하는 경우":
    "(2) When providing statistics, academic research, or market research in an unidentifiable form",
  "(3) 법령에 특별한 규정이 있는 경우":
    "(3) When there are special regulations in laws",
  "3. 회사는 관계 법령에 의하거나 수사기관의 요청에 의해 이용자의 개인정보를 제공한 경우 이를 당사자에게 고지하는 것을 원칙으로 합니다. 단, 법률상의 근거에 의해 부득이하게 고지를 못할 수도 있습니다.":
    "3. In principle, the company shall notify the parties concerned when the user's personal information is provided at the request of the investigative agency or by the relevant statutes. However, due to legal grounds, it may be unavoidable to notify.",
  "4. 회사는 아래와 같은 경우 이용자의 동의 하에 개인정보를 제3자에게 제공할 수 있습니다.":
    "4. The company may provide personal information to a third party with the consent of the user in the following cases:",
  "(1) 물품구매, 유료컨텐츠 이용 등의 배송 및 정산을 위해 이용자의 이름, 주소, 전화번호 등이 해당 쇼핑몰 업체, 유료컨텐츠 제공자, 배송업자에게 제공될 수 있습니다.":
    "(1) For the delivery and settlement of goods, paid contents, etc., the user's name, address, phone number, etc. may be provided to the relevant shopping mall company, paid content provider, and shipping company.",
  "(2) 각종 이벤트 행사에 참여한 회원의 개인정보가 해당 이벤트의 주최자에게 제공될 수 있습니다.":
    "(2) The personal information of the members who participated in various event events may be provided to the organizers of the event.",
  "5. 회사는 이용자에게 제공되는 서비스의 질을 향상시키기 위해 맞춤 서비스, 온라인광고 서비스, 쇼핑몰 서비스, 커뮤니티 서비스, 유료컨텐츠 서비스, 모바일 서비스, 보험ㆍ신용카드 등의 텔레마케팅 서비스, 통계작성 또는 시장조사 등 다양한 서비스를 제공할 목적으로 여러 분야의 전문 컨텐츠 사업자 혹은 비즈니스 사업자와 함께 파트너쉽을 맺을 수 있습니다.":
    "5. To improve the quality of services offered to users, the company can partner with professional content providers or business operators in various fields to provide custom services, online advertising services, shopping mall services, community services, paid content services, mobile services, insurance and credit cards, statistics or market research.",
  "제 7 조 개인정보의 처리위탁":
    "Article 7 Entrustment of The Processing of Personal Information;",
  "1. 회사는 제3자에게 이용자의 개인정보를 수집ㆍ보관ㆍ처리ㆍ이용ㆍ제공ㆍ관리ㆍ감독 및 교육ㆍ파기 등(이하 ":
    "1. The company shall collect, store, process, use, provide, manage, supervise, educate, destroy, etc. personal information of users to a third party (hereinafter referred to as: ",
  "라 한다)을 할 수 있도록 업무를 위탁(이하 ":
    "entrustment of duties (hereinafter referred to as ) so that he/ ",
  "이라 한다)하는 경우에는 다음 각 호의 사항 모두를 이용자에게 알리고 동의를 받습니다.":
    ") In the case of this, all the following matters shall be notified to the user and agreed upon:",
  "(1) 개인정보 처리위탁을 받는 자(이하 ":
    "(1) A person entrusted with the processing of personal information (hereinafter referred to as) ",
  "라 한다)": "It's called)",
  "(2) 개인정보 처리위탁을 하는 업무의 내용":
    "(2) Contents of entrusting the processing of personal information",
  "2. 회사는 이용자에게 다양하고 높은 수준의 서비스 제공을 위해 반드시 필요한 업무에 대해서는 아래와 같이 외부전문업체에 위탁하여 운영합니다.":
    "2. The company entrusts external specialists with the tasks necessary for providing various and high-level services to users as follows.",
  수탁업체: "Trustee",
  위탁업무내용: "Entrustment details",
  "개인정보 이용기간": "Personal information usage period",
  "나이스신용평가정보㈜": "Nice Credit Rating Information Co., Ltd.",
  "이용자 본인 확인": "User identification",
  "별도 저장하지 않음": "Don't save separately",
  KG이니시스: "KG Innisis",
  "유료서비스 결제처리 및 요금정산":
    "Payment processing for paid services and settlement of charges",
  "결제일 기준 5년": "5 years based on payment date",
  "제 8 조 이용자 및 법정대리인의 권리와 그 행사 방법":
    "Article 8 Rights of users and legal representatives and methods of exercise;",
  "1. 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수 있습니다.":
    "1. Users and legal representatives may inquire or modify their personal information and request cancellation at any time.",
  "2. 이용자 혹은 만 14세 미만 아동의 개인정보 조회 및 수정을 위해서는 마이페이지의 ‘개인정보관리’를 가입해지를 위해서는 ‘회원탈퇴’ 메뉴를 이용해서 직접 열람, 정정 또는 탈퇴가 가능합니다. 또는 개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하도록 하겠습니다.":
    '2. To check and modify personal information of users or children under 14 years of age, you can read, correct or withdraw directly using the "Membership withdrawal" menu. If you contact the person in charge of personal information protection in writing, by phone, or by e-mail, we will take action without delay.',
  "3. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리 결과를 제 3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.":
    "3. If a user requests correction of personal information errors, he/she shall not use or provide personal information until the correction is completed. Also, if you have already provided wrong personal information to a third party, we will notify the third party of the result of the correction without delay so that the correction can be made.",
  "4. 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 ‘개인정보의 보유기간 및 이용기간’에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.":
    '4. The company handles personal information that has been revoked or deleted at the request of the user or legal representative, as specified in the "Hold and Use Period of Personal Information" and is not allowed to read or use it for other purposes.',
  "제 9 조 개인정보의 보유기간 및 이용기간":
    "Article 9 Period of retention and use of personal information;",
  "1. 회사는 이용자가 이용자자격을 유지하고 있는 동안에는 이용자가 회사에 제공한 개인정보를 계속 보유합니다.":
    "1. The company retains personal information provided by the user to the company as long as the user maintains the user qualification.",
  "2. 개인정보제공에 대해 동의철회(회원탈퇴)할 경우 즉시 수집된 개인정보를 즉시 파기하며, 예외 규정을 제외하고 어떠한 목적으로도 사용할 수 없도록 합니다.":
    "2. In the event of withdrawal of consent (withdrawal of members) for the provision of personal information, the personal information collected shall be destroyed immediately and shall not be used for any purpose except for exceptions.",
  "3. 일시적인 목적에 의해 수집한 개인정보는 그 이용목적을 달성하면 지체 없이 파기합니다. 단, 이벤트응모를 위해 주소 등의 개인정보를 제공한 경우에는 이벤트상품 발송 및 이벤트응모 고객에 대한 고객응대를 위해 해당 이벤트의 종료시점으로부터 6개월간 해당 개인정보를 보유합니다.":
    "3. Personal information collected for temporary purposes shall be destroyed without delay if the purpose of use is achieved. However, if personal information such as address is provided for the event application, the personal information shall be held for six months from the end of the event to send event products and to respond to customers.",
  "4. 이용자가 본인이 회사의 이용자임을 증빙하기 위해 회사에 발송한 신분증 사본 등 서류일체는 본인확인 후 즉시 파기합니다.":
    "4. All documents, such as a copy of the identification card sent to the company by the user to prove that he/she is a user of the company, shall be destroyed immediately after identifying himself/herself.",
  "5. 법정대리인의 동의여부를 증빙하는 자료는 접수일로부터 최소 2년간 보유함을 원칙으로 하나, 법정대리인 동의 이후 2년 이내에 이용자가 회원탈퇴를 하는 경우에는 그 즉시 파기합니다.":
    "5. In principle, data proving the consent of the legal representative shall be held for at least two years from the date of receipt, but if the user withdraws from the membership within two years after the consent of the legal representative, it shall be destroyed immediately.",
  "6. 『상법』 및 『전자상거래 등에서의 소비자보호에 관한 법률』등 관련법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 법령에서 정한 일정한 기간 동안 이용자정보를 보관합니다. 이 때 회사는 보관하는 정보를 그 보관 이외의 목적으로 사용할 수 없습니다. 구체적인 사항은 다음과 같습니다.":
    "6. If it is necessary to preserve it in accordance with the provisions of the relevant statutes, such as the Commercial Act and the Consumer Protection Act in e-commerce, the company shall keep user information for a certain period of time as prescribed by the Act. At this time, the company shall not use the information stored for any purpose other than storage. The details are as follows:",
  "제 10 조 개인정보 파기절차 및 방법":
    "Article 10 Procedures and methods for destruction of personal information;",
  "회사는 이용자에게 사전에 고지하고 동의 받은 개인정보의 수집ㆍ이용목적이 달성되면 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.":
    "The company shall notify the user in advance and destroy the user's personal information without delay if the purpose of collecting and using the agreed personal information is achieved. The destruction procedures and methods are as follows.",
  "1. 파기절차": "1. Destruction procedure",
  "(1) 이용자가 입력한 개인정보는 수집ㆍ이용목적이 달성되면 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라":
    "(1) When the purpose of collection and use is achieved, the personal information entered by the user is transferred to a separate DB (separate filing cabinet in the case of paper) and is subject to information protection reasons according to internal policies and other relevant laws.",
  "(2) 제10조에 근거하여 일정기간 저장 후, 재생할 수 없는 방법으로 파기됩니다.":
    "(2) Based on Article 10, it is destroyed in a non-renewable manner after being stored for a certain period of time.",
  "(3) 별도 DB로 옮겨진 이용자의 개인정보는 법령에 의한 경우가 아닌 다른 목적으로 이용되지 않습니다.":
    "(3) The user's personal information transferred to a separate DB shall not be used for any purpose other than the case under the Act.",
  "2. 파기방법": "2. Destruction method",
  "(1) 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.":
    "(1) Delete personal information stored in electronic file format using technical methods that cannot be played back.",
  "(2) 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.":
    "(2) The personal information printed on the paper shall be shredded with a grinder or destroyed by incineration.",
  "제 11 조 개인정보보호를 위한 기술적ㆍ관리적 대책":
    "Article 11 Technical and management measures for the protection of personal information;",
  "1. 이용자의 개인정보는 이용자 자신이 설정한 비밀번호에 의해 우선적으로 보호되고 있습니다. 따라서 이용자는 절대로 비밀번호를 타인에게 알려주거나 공유해선 안되며, 회사 서비스의 이용완료 후에는 반드시 로그아웃(LOG-OUT)을 이용하여 웹 브라우저를 종료하여야 합니다. 특히 다른 사람과 컴퓨터를 공유하여 사용하거나 공공장소에서 이용한 경우에는 타인에게 개인정보가 알려지는 것을 막기 위하여 위 절차는 반드시 필요합니다. 이외에도 회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 및 훼손되지 아니하도록 안전성 확보를 위하여 다음과 같은 기술적 내지 관리적 대책을 강구하고 있습니다.":
    "1. The user's personal information is protected preferentially by the password set by the user himself. Therefore, users should never tell or share passwords to others, and they must exit the web browser using LOG-OUT after the company service is completed. In particular, if you share a computer with others or use it in public, the above procedure is necessary to prevent personal information from being known to others. In addition, the company takes the following technical and management measures to ensure safety so that personal information is not lost, stolen, leaked, tampered with, or damaged in handling personal information.",
  "2. 기술적 대책": "2. Technical countermeasures",
  "(1) 이용자의 비밀번호는 암호화 처리되어 이용자 개개인의 비밀번호를 알 수 없도록 되어 있습니다.":
    "(1) User's password is encrypted so that individual user's password cannot be known.",
  "(2) 개인정보의 훼손에 대비하여 정보를 수시로 백업하고 있으며 최신의 백신프로그램을 이용하여 이용자의 개인정보와 자료가 누출되거나 손상되지 않도록 하고 있습니다.":
    "(2) Information is frequently backed up in preparation for personal information damage, and personal information and data are not leaked or damaged using the latest vaccine program.",
  "(3) 암호화 통신 등을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.":
    "(3) We provide secure communication of personal information on the network through cryptographic communication.",
  "(4) 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며 기타 시스템적으로 보완성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.":
    "(4) The intrusion prevention system is used to control unauthorized access from outside, and all other technical devices are prepared to ensure complementary system.",
  "3. 관리적 대책": "3. Management measures",
  "(1) 개인정보보호책임자와 최소한으로 구성된 인원들에 대해서만 개인정보 관련 처리를 한정시키고 있으며 기타 인원에 대해서는 개인정보에 대한 접근권한을 제한하고 있습니다.":
    "(1) The government restricts personal information processing to the person in charge of personal information protection and the minimum number of persons, and restricts access to personal information to other persons.",
  "(2) 개인정보처리인원에 대한 교육을 통하여 개인정보보호의무 등 관련된 의무를 항상 강조하고 있으며 개인정보처리방침이행사항 및 담당자의 준수 여부를 수시로 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 최선의 노력을 기울이고 있습니다.":
    "(2) Through the training of personal information processing personnel, we always emphasize the obligation to protect personal information, and we make every effort to check the implementation of the personal information processing policy and compliance with the person in charge.",
  "4. 회사는 이용자 스스로의 실수 또는 부주의 기타 기본적인 인터넷의 위험으로 인해 발생되는 개인정보유출 및 훼손에 대해서는 어떠한 책임도 부담하지 않습니다.":
    "4. The company shall not take any responsibility for personal information leakage or damage caused by the user's own mistakes or carelessness or the risk of the basic Internet.",
  "제 12 조 개인정보보호책임자 및 담당자의 연락처":
    "Article 12 Contact information of the person in charge of personal information protection and the person in charge;",
  "이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 회사는 개인정보보호책임자를 두고 있습니다. 개인정보와 관련된 문의사항이 있으시면 아래의 개인정보보호책임자에게 연락주시기 바랍니다. 문의하신 사항에 대해서 신속하고 성실하게 답변해드리겠습니다":
    "The company has a person in charge of personal information protection to protect users' personal information and handle complaints related to personal information. If you have any questions regarding personal information, please contact the person in charge of personal information protection below. We will answer your inquiry promptly and sincerely.",
  "[개인정보보호 책임자]": "[Personal Information Data Protection Officer]",
  "이름 : 김재우": "Name: Kim Jae-woo",
  "소속 : ㈜팡스카이 이사": "Affiliate: Director Pangsky Co., Ltd. Ltd.",
  "[개인정보관리 담당부서]": "[Personal Information Management Department]",
  "부서 : 서비스운영팀": "Department: Service Operation Team",
  "전화번호 : 070-4658-9412": "Phone number: 070-4658-9412",
  "제 13 조 청소년보호를 위한 활동":
    "Article 13 Activities for the Protection of Juveniles",
  "회사는 각종 청소년 유해정보로부터 청소년을 보호하고자 관련법에 따라 만 19세미만의 청소년이 유해정보에 접근할 수 없도록 청소년보호정책을 마련하여 시행하고 있습니다. 또 회사는 청소년의 건전한 성장을 저해하는 음란, 불법 유해정보와 비윤리적, 반사회적 행위에 대해서는 엄격하게 제재하기 위하여 다음과 같이 활동하고 있습니다.":
    "In order to protect juveniles from various harmful information of juveniles, the company has prepared and implemented a youth protection policy to prevent juveniles under the age of 19 from accessing harmful information under the relevant law. In addition, the company is working as follows to strictly sanction pornography, illegal harmful information, unethical and antisocial behavior that hinders the healthy growth of teenagers.",
  "1. 청소년유해정보로부터의 청소년보호계획의 수립":
    "1. Establishment of a youth protection plan from youth harmful information",
  "2. 청소년유해정보에 대한 청소년 접근제한 및 관리조치":
    "2. Restrictions and management measures on access to juvenile harmful information",
  "3. 정보통신업무 종사자에 대한 청소년유해정보로부터의 청소년보호를 위한 교육":
    "3. Education for the protection of juveniles from harmful information of juveniles for persons engaged in in information and communication services;",
  "4. 청소년유해정보로 인한 피해상담 및 고충처리":
    "4. Counseling for damages and handling grievances caused by youth harmful information",
  "5. 그 밖에 청소년유해정보로부터 청소년을 보호하기 위하여 필요한 사항":
    "5. Other matters necessary to protect juveniles from harmful information of juveniles;",
  "제 14 조 개인정보 관련 신고 및 분쟁조정":
    "Article 14 Reporting related to personal information and mediation of disputes;",
  "개인정보에 대한 상담이 필요한 경우에는 다음의 곳으로 문의하시길 바랍니다.":
    "If you need consultation on personal information, please contact the following places.",
  "- 개인정보침해신고센터 전화: 1336, 홈페이지: www.cyberprivacy.or.kr":
    " Call the Privacy Report Center: 1336, homepage: www.cyberprivacy.or.kr",
  "- 개인정보분쟁조정위원회 전화: 02-405-4747, 홈페이지: www.kopico.or.kr":
    " Personal Information Dispute Mediation Committee Phone: 02-405-4747, homepage: www.kopico.or.kr",
  "#NAME?": "",
  "제 15 조 정책 변경에 따른 공지의무":
    "Article 15 Disclosure obligations following a change in policy;",
  "법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 시에는 변경되는 개인정보처리방침을 시행하기 최소 7일전에 서비스 초기화면을 통해 변경이유 및 내용 등을 공지하도록 하겠습니다.":
    "If there is an additional deletion or modification of the contents according to the legal policy or security technology, we will notify the reason and contents of the change through the service initialization page at least 7 days before implementing the personal information processing policy.",
  부칙: "Attachment",
  "본 개인정보처리방침은 2017년12월07일부터 시행됩니다.":
    "This personal information processing policy will take effect from December 07, 2017.",
  "2016년 05월 09일부터 시행되던 종전의 정책은 본 정책으로 대체합니다.":
    "The previous policy, which took effect on May 9, 2016, replaces this policy.",
  "안녕하세요. 팡스카이입니다.": "Hello, This is Pangsky.",
  "팡스카이는 게임 퍼블리싱 서비스를 기반으로":
    "Pangsky is dedicated to game publishing services.",
  "창의적인 상상력을 바탕으로 온라인게임과 모바일게임, ":
    "Based on creative imagination, ",
  "게임퍼블리싱을 위한":
    "we have published a variety of both PC and Mobile games. ",
  "해외 네트워크": "Overseas Network ",
  "중국의 바이두를 비롯하여 약 40여 게임관련":
    "established a close cooperative network with about 40 game-related companies,",
  "기업들과 긴밀한 협력 네트워크를 구축 완료": " including Baidu in China.",
  "게임 확보 여력": "Game Publishing Capability",
  "해외에서 성공 케이스를 가진":
    "We have helped games developed by Chinese game companies",
  "중국 개발 게임들에 관한 판권 확보 가능":
    "to secure copyright and become successful overseas",
  "국내 네트워크": "Domestic Network in Korea",
  "팡스카이의 창업 멤버들은 한국 게임 업계 평균 19년":
    "Pangsky's founding members are 19 years experienced on average in the Korean game industry.",
  "경력을 가지고 있으며, 다양한 인맥 및 리소스 네트워크를 보유":
    "and we have diverse network of connections and resources",
  "안정적인 운영시스템": "Reliable Operating System",
  "게임 퍼블리싱 업무 진행 경험을 바탕으로":
    "Based on our experience in game publishing,",
  "뛰어난 리스크 관리 및 빠른 업무처리 및":
    "We have superior risk management and rapid tasking abilities,",
  "Big Data 연구소를 보유하여 체계적 마케팅 가능":
    "Together with systematic marketing capability with Big Data tech team.",
  " ‘묵혼’ 태국 출시": ' "Dark Story Origin" Launched in Thailand',
  " ‘RF 온라인’ 일본 서비스": ' "RF Online" Launched in Japan',
  " ‘포트리스 배틀로얄‘ 서비스 출시": ' "Fortress Battle Royale" Launched',
  "‘묵혼’ 온라인 대만 출시": ' "Mook Hon" Launched in Taiwan',
  " ‘고수’ 대만 출시": ' "Master" launched in Taiwan',
  " ‘묵혼’ 온라인 한국 출시": ' "Mook Hon" Launched in Korea',
  "‘고수‘ 모바일 한국 출시": ' "Master" Mobile Game Launched in Korea',
  " 온라인 SF MMORPG ‘RF온라인’ 서비스 출시":
    ' Online SF MMORPG "RF Online" Launched',
  " 씨씨알컨텐츠트리와 ‘포트리스 2M’ 퍼블리싱 계약 체결":
    " Signed a publishing contract for “Fortress 2M” with CCR Contents Tree",
  " 모바일 MMORPG ‘무협왕’ 앱 스토어 출시":
    ' Mobile MMORPG "Lost Emperor" Launched in App Store',
  " 모바일 MMORPG ‘리그너스 대륙전기‘ 구글 플레이, 원 스토어 출시":
    ' Mobile MMORPG "Ligners Continental War" Launched in One Store and Google Play Store',
  "모바일 SRPG ‘삼국 어웨이큰＇구글 플레이, 원 스토어 출시":
    ' Mobile SRPG "Three Kingdoms Awaken" Google Play Launches One Store',
  " 모바일 MMORPG ‘십만대적검 : 리부트‘출시":
    ' Mobile MMORPG "100,000 Red Sword: Reboot" is released',
  " 모바일 MMORPG ‘백가쟁패’ 구글 플레이, 원 스토어 출시":
    ' Mobile MMORPG "Hundred Hegemony" Google Play Launches One Store',
  "모바일 MMORPG ‘신의 아이들‘구글 플레이, 앱 스토어 ,원 스토어 출시":
    'Mobile MMORPG"God\'s Children" launched on Google Play, App Store, and One Store',
  " 모바일 MMORPG ‘십만대적검’ 구글 플레이, 원 스토어 출시":
    ' Mobile MMORPG "Hundreds of thousands of Red Swords" launched on Google Play, One Store',
  " 모바일 MMORPG ‘여신전기‘ 구글 플레이, 원 스토어 출시":
    ' Mobile MMORPG "Goddess War" launched on Google Play, One Store',
  " 모바일 무협 MMORPG ‘군도‘ 구글 플레이, 원 스토어 출시":
    ' Mobile martial arts MMORPG "Gundo" launched on Google Play, One Store',
  " 웹 MMORPG ‘촉산협객전‘ 서비스 출시":
    ' MMORPG "Culture Sanctuary Exhibition" Web Service Launched',
  " 블루사이드와 ‘킹덤언더파이어‘ 모바일 게임 개발 업무 협약 체결":
    " Signed an agreement for the development of “Kingdom Under Fire” mobile games with Blueside",
  " 모바일 게임 ‘형님 모바일’ 구글 플레이 무협모바일게임 장르 1위 달성":
    ' Mobile game "Brother Mobile" achieved No. 1 in the martial arts mobile game genre on Google Play',
  " 웹 무협 MMORPG ‘검우강호’ 사전 등록자 15만명 기록":
    ' 150,000 pre-registrants of web martial arts MMORPG "Kumwoo Gangho" recorded',
  " 웹게임 ‘무쌍 온라인‘ 서비스 출시": ' "Online OROCHI" Web service Launched',
  "㈜팡게임, ‘㈜팡스카이’로 사명 변경":
    " Pangame Co., Ltd. changed its name to PangSky Co., Ltd.",
  " 모바일 해적 전략시물레이션 게임 ‘무적함대1942’ 구글 플레이 출시":
    ' Mobile pirate strategy simulation game "Invincible Fleet 1942" Launched in Google Play',
  " 모바일 MMOARPG ‘칼에 깃든 바람‘ 원 스토어 출시":
    ' Mobile MMOARPG "The Wind in the Sword" Launched in One Store ',
  " 모바일게임 ‘대황제M’ 구글 플레이,원 스토어 출시, 구글 플레이 전략게임 1위 달성":
    " Mobile game “The Great Emperor M” launched on Google Play and One Store, achieving No. 1 strategy game on Google Play",
  " 무협 웹게임 ‘운중가온라인‘ 서비스 오픈":
    ' Martial arts web game "Woonjungga Online" service launched',
  " PlamJoy와 웹게임 ‘운중가온라인’ 한국 퍼블리싱 계약 체결":
    " Signed a publishing contract with PlamJoy for the web game “Woonjungga Online” in Korea",
  " PC온라인게임 ‘대황제’ 네이버 등 8개사 채널링 계약":
    ' PC online game "Great Emperor" : Channeling contracts for eight companies including Naver',
  "모바일게임 ‘바다속이야기’ 구글 플레이, N스토어 출시":
    'Mobile Game "The Underwater Story" launched on Google Play, N Store',
  " PC온라인게임 ‘데빌에이지‘ 서비스 오픈":
    ' PC Online Game "Devil Age" Service Launched',
  " 벤처기업확인(벤처기업협회)":
    " Venture Enterprise Confirmation (Venture Enterprise Association)",
  " PC온라인게임 “뉴던전히어로” 오픈":
    'PC Online Game "New Dungeon Hero"  launched',
  " ‘기업부설연구소 설립(미래창조과학부)":
    " Establishment of Corporate Research Institute (Ministry of Science, ICT and Future Planning)",
  " 중국 최대 검색포털 ‘바이두‘의 “바이두모바일게임＂과 협력체결":
    " Signed a partnership with China's largest search portal, Baidu and Baidu Mobile Game.",
  "모바일 게임 “구몽:전생의기억“  구글 플레이 출시":
    'Mobile Game "Gumong: Memories of Past Life"   launched on Google Play ',
  " 모바일 게임 “케로로 액션히어로 for Kakao 구글 플레이, 앱 스토어 ,원 스토어 출시":
    ' Mobile game "Keroro Action Hero for Kakao" launched on Google Play, App Store, One Store',
  " 국내 모바일게임사 서비스팀 인수":
    " Acquisition of domestic mobile game service team",
  " PC온라인게임 “구몽온라인“ 6개 채널링 동시오픈":
    ' PC Online Game "Gumong Online" Marketing on 6 channels Simultaneously',
  " 중국 ‘광주명조네트워크’와 PC온라인게임 “구몽온라인“ 한국 독점 서비스 계약체결":
    ' Signed an exclusive contract in Korea market with "Guangzhou Myungjo Network" for publishing PC online game "Gumong Online" ',
  " ‘㈜투윈게임즈’와 PC온라인게임 “원더킹“ 채널링 계약 체결":
    ' Signed a PC online game "Wonder King" channeling contract with "Two Win Games"',
  " ‘㈜이야소프트’와 PC온라인게임 “에다전설“ 국내 서비스 계약 체결":
    " Signed a domestic publishing contract with EYASOFT Co., Ltd. for publishing EDA Legend",
  " 중국 ‘자모조이’와 모바일게임 “스와트앤좀비” 서비스 계약 체결":
    ' Signed a domestic publishing contract with China\'s "Zamo Zoey" for publishing Mobile game "Swat & Zombie"',
  "‘㈜미디어로그’와 “스와트앤좀비“ 해외 서비스 계약 체결":
    " Signing an overseas publishing contract wtih Media Log and Swat & Zombie.",
  " www.panggame.com 사이트 오픈": " Open the website： www.panggame.com",
  " 주식회사 팡 게임 설립": " PangGame, Inc. Established",
  "‘㈜더파이널파이브’와 PC온라인게임 ‘’와인드업‘ 서비스 계약 체결":
    ' Signed a contract with "The Final Five" for publishing PC online game ``wind-up"" ',
  "팡스카이가 걸어온 길": " The path with PangSky",
  "전화 : ": "Phone: ",
  "팩스 : ": "Fax:",
  "서울특별시 강남구 논현로80길 25":
    "20, Digital-ro 31-gil, Guro-gu, Seoul, Republic of Korea",
  "팡스카이와 함께 꿈을 만들어 보실래요?":
    "Would you like to realize your dream with PangSky?",
  "지원하러 가기": "Go to apply",
  "No tie! 편안한 복장": "No tie! Comfortable outfit",
  "자율 복장으로 출퇴근길을 가볍게~!": "Wear comfortable clothes at work!",
  "사내 카페테리아 운영": "In-house cafeteria operation",
  "카페테리아, 미니 피트니스 등 휴게 공간": "Cafeteria, mini fitness, etc.",
  "해피 급여 데이": "Happy salary day",
  "급여일에 한해 점심시간 30분 연장":
    "30 minutes extended lunch break on pay days only",
  "명절 선물 지급": "Holiday gift payment",
  "설,추석 명절에 백화점 상품권 지급":
    "Department store gift certificates provided on Lunar New Year and Chuseok holidays",
  "자율 연차제": "Flexible annual vacation system",
  "근로기준법에 따른 연차 제도 실시":
    "Implementation of the vacation system according to the Labor Standards Act",
  "시차 출퇴근제": "Flexible Working Hours",
  "출근10:00 ~ 10:30 | 퇴근 19:00 ~ 19:30":
    "Start work at 10:00 ~ 10:30 | After work at 19:00 ~ 19:30",
  "야간 근무자 지원": "Night shift support",
  "야근 식대, 야근 택시비": "Overtime meals, overtime taxi fees",
  "각종 경조사 지원": "Support for various congratulations and events",
  "경조금 및 경조 휴가": "Congratulations and condolences vacation",
  패밀리데이: "Family Day",
  "매월 셋째주 금요일 17시 조기 퇴근":
    "Finish work early at 17:00 on the third Friday of every month",
  "인재 추천 보상금": "Talent Recommendation Reward",
  "추천 인재 정규직 전환 시 추천자에게 직급에 따른 보상 지급":
    "Recommendation rewards to recommenders when converting to full-time employees",
  "회식비 지원": "Support for teamwork expenses",
  "팀원 단합을 위한 월 단위 회식비 지원":
    "Monthly dinner fee support for team members",
  "생일 축하 및 선물 지급": "Happy birthday and gift giving",
  "백화점 상품권 및 유급 휴가 0.5일":
    "Department store gift certificate and 0.5 days paid vacation",
  소개: "Introduction",
  팡스카이소개: "Pangsky",
  게임: "Game",
  미디어: "Media",
  보도자료: "Press Release",
  "미디어 행사": "Media Event",
  문화: "Culture",
  기업문화: "Corporate culture",
  복지: "Welfare",
  "팡스카이 소식": "Pangsky News",
  채용: "Recruitment",
  "채용 소개": "Employment",
  투자정보: "Investment information",
  기업지배구조: "Corporate Governance",
  "광고/제휴 문의": "Advertising/Affiliate",
  "기업윤리 신고센터": "Corporate Ethics Report Center",
  "개인정보 처리방침": "Privacy Policy",
  "팡스카이 스토어": "Pangsky Store",
  "게임 고객센터": "Game Customer Center",
  "광고/제휴 문의 안내": "Advertising/Affiliate",
  "팡 스카이는 보다 더 투명하고": "Pang Sky is more transparent and",
  "윤리적인 기업문화의 정착을 위해 기업 윤리 신고센터를 운영합니다.":
    "We operate a corporate ethics report center to establish an ethical corporate culture.",
  "임직원의 불공정한 업무수행,금품,접대 등 부당한 요구 및 업무 중 부당한 회계처리":
    "Unfair performance of employees, unfair demands such as money and goods, entertainment, and unfair accounting treatment during work",
  "기타 부정,비리 행위를 상담, 신고 할 수 있습니다.":
    "Other irregularities and irregularities can be consulted and reported.",
  "임직원,협력사, 기타 제보와 관련있는 거래 당사자, 제 3자 등":
    "Executives and employees, business partners, other parties related to information, third parties, etc.;",
  "그 누구라도 실명 또는 익명으로 신고 가능하며, 신고된 내용은":
    "Anyone may report it by real name or anonymously, and the details of the report shall be:",
  "팡스카이 감사 주관하에 공정하고 엄중하게 조사 처리 됩니다.":
    "The investigation will be conducted fairly and strictly under the supervision of the Pangsky audit.",
  "제보자의 신분과 상담,신고 내용 등은 철저히 비밀로 보장되며,":
    "The identity, counseling, report details, etc. of the informant shall be strictly confidential.",
  "제보자는 어떠한 경우에도 정당한 제보를 이유로":
    "In any case, the informant shall, on the grounds of justifiable information,",
  "불이익이나 처벌을 받지 않음을 약속 드립니다.":
    "I promise not to be penalized or punished.",
  "접수 시 빠른 시일 내에 처리 할 수 있도록 최선을 다하겠습니다.":
    "We will do our best to process the application as soon as possible.",
  "상담·신고하기": "Consultation/Report",
  "처리결과 확인하기": "Check the processing result",
  "게임 고객센터: panggamehelp@panggame.com":
    "Game Customer Center: panggamehelp@panggame.com",
  "(주)팡스카이 대표이사 지헌민, 대표이사 조호현, 사업자등록번호 : 119-86-62250, 통신판매업 신고번호 : 제 2014-서울강남-01064호":
    "Pangsky, CEO LEE BYUNGJIN, CEO Cho HoHyun, business registration number: 119-86-62250, mail-order business report number: 2022-Seoul Guro-2036",
  "주소 : 서울시 강남구 논현로80길 25(역삼동,정우빌딩), 전화 : 070-4658-9400, 팩스 : 070-8233-9383":
    "Address: Room 1201, 20, Digital-ro 31-gil, Guro-gu, Seoul (Guro-dong, Ace Techno Tower 5th), Phone: 070-4658-9400, Fax: 070-8233-9383",
  "2. 이용자가 회사의 서비스를 이용하기 위해서는 회원가입 시 개인정보를 입력하셔야 하며, 회사는 필수입력항목과 선택입력항목으로 구분하여 개인정보를 수집하고 있습니다. 필수입력항목은 회원가입을 위해 반드시 제공해야 하는 개인정보이며, 선택입력항목은 입력하지 않으셔도 회원가입이 가능한 개인정보를 의미합니다. 필수항목과 선택항목은 다음과 같습니다.":
    "2. In order for users to use the company's services, they must enter personal information when signing up for membership, and the company collects personal information by dividing it into mandatory and optional items. Mandatory input items are personal information that must be provided for membership registration, and it means personal information that can be registered as a member without entering optional input items. Required and optional items are as follows.",
  "[일반회원]": "[General Membership]",
  "- 필수항목 : 이름, 아이디(ID), 비밀번호, 만 14세 미만인 경우 법정대리인 정보, 전화번호, 휴대전화번호, 이용자정보 확인 질문/답변 주로 쓰는 이메일, 네이버, 페이스북, 구글 등의 SNS 계정 정보, CI, DI":
    "- Required items: Name, ID, password, legal representative information, phone number, mobile phone number, user information verification question/answer frequently used email, SNS account information such as Naver, Facebook, Google, etc. , CI, DI",
  "3. 수집방법": "3. Collection method",
  "홈페이지(이용자가입), 서면양식, 전화, 기타방법":
    "Homepage (user registration), written form, telephone, other methods",
  "2. 일부 유료 서비스 이용 시 유료 결제를 위해 수집하는 결제정보 별 수집목적은 다음과 같습니다.":
    "2. When using some paid services, the purpose of collection for each payment information collected for paid payment is as follows.",
  "(1) 은행계좌정보, 신용카드정보, 핸드폰번호, 전화번호: 유료정보이용에 대한 과금":
    "(1) Bank account information, credit card information, mobile phone number, phone number: billing for the use of paid information",
  "- Internet Explorer: 도구 > 인터넷 옵션 > 개인정보 > 고급":
    "- Internet Explorer: Tools> Internet Options> Personal Information> Advanced",
  "- Chrome: 설정 > 설정 표시 > 개인정보의 콘텐츠 설정 버튼 > 쿠키 그 외 브라우저의 경우 브라우저 별 설정 방식에 따릅니다.":
    "- Chrome: Settings> Display settings> Personal information content settings button> Cookies Other browsers follow the browser-specific settings.",
  '1. 회사는 제3자에게 이용자의 개인정보를 수집ㆍ보관ㆍ처리ㆍ이용ㆍ제공ㆍ관리ㆍ감독 및 교육ㆍ파기 등(이하 "처리"라 한다)을 할 수 있도록 업무를 위탁(이하 "개인정보 처리위탁"이라 한다)하는 경우에는 다음 각 호의 사항 모두를 이용자에게 알리고 동의를 받습니다.':
    '1. The company entrusts the business to a third party to collect, store, process, use, provide, manage, supervise, educate, destroy, etc. (hereinafter referred to as "processing") of users\' personal information (hereinafter referred to as "personal information"). In the case of "consignment of information processing"), all of the following matters are notified to the user and consent is obtained.',
  '(1) 개인정보 처리위탁을 받는 자(이하 "수탁자"라 한다)':
    '(1) Person who receives personal information processing consignment (hereinafter referred to as "consignee")',
  "(1) 계약 또는 청약철회 등에 관한 기록":
    "(1) Records on contract or subscription withdrawal, etc.",
  "보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률":
    "Reason for retention: Act on Consumer Protection in Electronic Commerce, etc.",
  "보존 기간 : 5년": "Retention period: 5 years",
  "(2) 대금결제 및 재화 등의 공급에 관한 기록":
    "(2) Records on payment and supply of goods, etc.",
  "(3) 소비자의 불만 또는 분쟁처리에 관한 기록":
    "(3) Records on consumer complaints or dispute settlement",
  "보존 기간 : 3년": "Retention period: 3 years",
  "(4) 전자금융 거래에 관한 기록":
    "(4) Records on electronic financial transactions",
  "보존 이유 : 전자금융거래법":
    "Reason for retention: Electronic Financial Transactions Act",
  "(5) 표시/광고에 관한 기록": "(5) Records on display/advertisement",
  "보존 기간 : 6개월": "Retention period: 6 months",
  "(6) 본인 확인에 관한 기록": "(6) Records on identification",
  "보존 이유 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률":
    "Reason for retention: Act on Promotion of Information and Communication Network Utilization and Information Protection, etc.",
  "(7)서비스 이용 관련 개인 정보(접속기록, 이용기록, IP정보)":
    "(7) Personal information related to service use (access records, usage records, IP information)",
  "보존 이유 : 통신비밀보호법":
    "Reason for retention: Protection of Communications Secrets Act",
  "보존 기간 : 3개월": "Retention period: 3 months",
  개인정보처리방침: "Privacy Policy",
  "- 경찰청 사이버테러대응센터 홈페이지: www.ctrc.go.kr":
    "-National Police Agency Cyber ​​Terror Response Center website: www.ctrc.go.kr",
  "회사 소개 영상": "Company introduction video",
  "한국을 포함한 전 세계에 특별한 재미와 경험을 제공하는 기업입니다.":
    "It is a company that provides special fun and experiences to all over the world, including Korea.",
  "그리고 콘텐츠 사업으로 영역을 확장하고 있으며,":
    "And it is expanding its scope to the content business.",
  "새로운 도전을 통해 글로벌 고객에게 즐거움을 선사할 것입니다.":
    "We will bring joy to global customers through new challenges.",
  "서울시 강남구 논현로80길 25(역삼동,정우빌딩)":
    "20, Digital-ro 31-gil, Guro-gu, Seoul, <br/>Republic of Korea",
  조직도: "Organization Chart",
  "(주)팡스카이 대표이사 지헌민, 대표이사 조호현    서울시 강남구 논현로 80길 25    전화:070-4658-9412    팩스:070-8233-9383":
    "Pangsky, CEO Ji Heonmin, CEO Cho HoHyun 25, Nonhyeon-ro 80-gil, Gangnam-gu, Seoul Tel: 070-4658-9412 Fax: 070-8233-9383",
  "사업자등록번호:119-86-62250호    통신판매업 신고번호 제2014-서울강남-01064호":
    "Business registration number: 119-86-62250 Mail-order business report number 2014-Seoul Gangnam-01064",
  "기업소식": "Announce",
  "공고": "Notice"
};
