export const m = {
  "검색어를 입력해주세요": "請輸入關鍵詞",
  "제 1 조 총칙": "第一條 總則",
  "1. ㈜팡스카이(이하 ‘회사’라 합니다.)는 서비스를 제공하기 위해 필요한 필수항목을 이용자의 동의 하에 수집하고 있습니다. 개인정보란 생존하는 개인에 관한 정보로서 해당 정보에 포함되어 있는 성명 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보를 말합니다.":
    "1. （株）Pangsky（以下稱“公司“）爲向用戶提供服務，以獲得用戶同意爲前提，收集必要的信息。個人信息是指有關生存狀態下的個體的信息，可以依據此信息中的姓名等內容，識別該個體的信息。",
  "2. 회사는 이용자의 개인정보를 소중히 처리하며, 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 방송통신위원회가 제정한 『개인정보보호지침』을 준수하고 있습니다.":
    "2. 公司對於個人信息的處理時，本着尊重及謹慎的態度，且會嚴格依據《通信祕密保護法》、《電信事業法》、《信息通信網使用促進及信息保護等相關法律》及廣播通信委員會制定的《個人信息保護指南》等相關法則。",
  "3. 회사는 관련 법령에 의거하여 개인정보처리방침을 통해 이용자의 권익 보호를 위해 최선을 다하고 있습니다.":
    "3. 公司嚴格依據相關法律，遵循個人信息處理方針，爲保護用戶權益盡着最大努力。",
  "4. 회사는 개인정보처리방침을 홈페이지 첫 화면 하단에 공개함으로써 이용자가 언제나 용이하게 보실 수 있도록 조치하고 있습니다.":
    "4. 公司將會把個人信息處理方針展示在官網首頁下方，以便用戶隨時查閱。",
  "제 2 조 수집하는 개인정보 항목 및 수집방법":
    "第2條 個人信息收集方法以及進行收集的項目",
  "1. 회사는 회원가입 시 서비스 제공을 위해 필요한 최소한의 개인정보만을 수집합니다.":
    "1. 公司註冊會員時，只收集提供服務所需的最低限度的個人信息。",
  "2. 이용자가 회사의 서비스를 이용하기 위해서는 회원가입 시 개인정보를 입력하셔야 하며, 회사는 필수입력항목과 선택입력항목으로 구분하여 개인정보를 수집하고 있습니다. 필수입력항목은 회원가입을 위해 반드시 제공해야 하는 개인정보이며, 선택입력항목은 입력하지 않으셔도 회원가입이 가능한 개인정보를 의미합니다. 필수항목과 선택항목은 다음과 같습니다.":
    "2. 用戶爲了使用公司的服務，在加入會員時必須輸入個人信息，公司將個人信息分爲必填內容和選擇輸入項目進行收集個人信息。 必填內容是指爲加入會員必須提供的個人信息，選擇輸入項目是指即使不輸入也可以加入會員的個人信息。 必需項目和選擇項目如下。",
  "[일반회원]": "[普通會員]",
  "- 필수항목 : 이름, 아이디(ID), 비밀번호, 만 14세 미만인 경우 법정대리인 정보, 전화번호, 휴대전화번호, 이용자정보 확인 질문/답변 주로 쓰는 이메일, 네이버, 페이스북, 구글 등의 SNS 계정 정보, CI, DI":
    "- 必須項目: 姓名、ID、密碼、未滿14週歲者，法定代理人信息、電話號碼、手機號碼、用戶信息確認提問/回覆 主要使用的電子郵件、naver、facebook、谷歌等SNS賬號信息、CI、DI",
  "3. 수집방법": "3. 收集方法",
  "홈페이지(이용자가입), 서면양식, 전화, 기타방법":
    "主頁（註冊會員）、書面格式、電話、其他方法",
  "4. 회사는 설문조사, 경품발송 등을 위한 목적으로 이용자의 개인정보 기재를 요청하거나 보다 나은 서비스를 위하여 소프트웨어 정보를 추출할 수 있으며, 수집한 목적 등이 완료된 경우에는 관계법령에서 정한 경우를 제외하고 수집된 개인정보가 열람 또는 이용될 수 없도록 처리합니다.":
    "4. 公司以問卷調查、贈品發送等爲目的，可以要求用戶填寫個人信息或爲更好的服務提取軟件信息，收集的目的達成後，除非相關法令責令閱覽的情況外，不得擅自閱覽或利用收集的個人信息。",
  "5. 유료서비스를 이용하고자 하는 이용자는 본인의 이용료결제에 필요한 범위내에서 은행계좌정보, 신용카드정보 등의 항목을 입력해야 하며 이는 이용료 결제를 위한 목적으로 사용됩니다.":
    "5. 想要使用付費服務的用戶必須在本人使用費結算所需的範圍內輸入銀行賬戶信息、信用卡信息等項目，這些信息用於支付使用費。",
  "6. 회사는 서비스의 불량이용자 규제를 위해 IP주소, 쿠키정보, 불량이용기록 등을 수집할 수 있습니다.":
    "6. 公司爲限制惡意用戶的目的，可收集IP地址、Cookie信息、不良利用記錄等。",
  "제 3 조 개인정보 수집 및 이용에 대한 동의":
    "第三條 個人信息收集與利用的同意",
  "회사는 이용자의 개인정보 수집 및 이용에 대한 동의를 받고 있습니다. 이용자의 개인정보 수집과 관련하여 회원가입 시 개인정보처리방침에 대한 동의절차를 마련하여, 동의 버튼을 클릭하면 개인정보 수집 및 이용에 대해 동의한 것으로 봅니다. 다만, 아래의 경우에는 개인정보 수집 및 이용에 대한 이용자의 사전 동의를 구하지 않을 수 있습니다.":
    "公司正在徵得用戶對個人信息收集及利用的同意。 關於收集用戶個人信息的問題，在註冊會員時準備了個人信息處理方針的同意程序，點擊同意按鈕，就可以看出同意收集及使用個人信息。 但是，以下情況可以不徵得用戶對個人信息收集及使用的事前同意。",
  "1. 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한 개인정보로서 경제적 기술적인사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우":
    "1.爲了履行有關提供信息通信服務的合同，作爲必要的個人信息，因經濟或者技術方面的原因，很難獲得正常情況下的同意的情況",
  "2. 정보통신서비스의 제공에 따른 요금정산을 위하여 필요한 경우":
    "2. 根據信息通信服務的提供，需要結算費用時",
  "3. 다른 법률에 특별한 규정이 있는 경우": "3. 其他法律有特別規定的情況",
  "제 4 조 개인정보의 수집 및 이용목적": "第4條 個人信息的收集與使用目的",
  "1. 회원 가입 시 수집하는 개인정보 별 수집목적은 다음과 같습니다.":
    "1. 註冊會員時收集的個人信息分類別收集目的如下。",
  "(1) 이름(실명), 아이디, 비밀번호: 신용평가기관을 통한 실명확인, 중복가입확인, 서비스 이용에 따른 본인식별, 연령제한 서비스, 인구통계학적 분석, 고객센터의 운영, 불량이용자의 부정한 이용재발 방지":
    "(1)姓名(實名)、ID、密碼:通過信用評價機構進行實名驗證、是否重複加入驗證、根據服務的利用進行是否本人的識別、年齡限制服務、人口統計學分析、客服中心的運營、防止惡意用戶的不正當使用再現",
  "(2) 비밀번호 힌트/답: 이용자가 비밀번호를 분실한 경우 이에 고객지원을 위한 본인확인용 질문/답":
    "（2）密碼提示/答案:用戶丟失密碼時，爲了支援顧客，需進行本人確認用 提問/回覆",
  "(3) 전자우편: 공지사항 전달, 본인 의사확인, 고객문의 답변 등 원활한 의사소통 채널확보, 신규 서비스 나 이벤트 정보 등 최신 정보 등의 안내":
    "(3)電子郵件: 傳達公告事項、確認是否本人意願、回覆客戶諮詢等暢通的溝通渠道、新增服務或活動信息等最新信息等通知",
  "(4) 거주지 주소: 지역별 이벤트 등의 정보 제공, 지역별 커뮤니티 서비스 제공":
    "(4)居住地地址: 提供各地區活動等信息，提供各地區社區服務",
  "(5) 연락처(전화번호/휴대폰): 비밀번호 분실 시 SMS 통보 및 고객지원을 위한 연락처 확보":
    "(5)聯繫方式（電話號碼/手機）: 客戶遺忘密碼時，通過SMS進行告知，以及爲協助用戶而備份的聯繫方式",
  "(6) 부모님(법정대리인) 성명 14세 미만 이용자(이하 “아동”이라 합니다)의 개인정보 수집시 법정대리인 동의를 얻기 위한 실명 확인 및 법정 대리인의 아동 개인정보 열람 등을 위한 고객지원, 만18세 미만 청소년의 가입시 법정대리인 동의를 얻기 위한 실명 확인 및 게임물 이용내역의 고지":
    '(6) 父母（法定代理人）姓名:收集未滿15歲的使用者（以下稱"兒童"）個人信息時，爲得到法定代理人同意的實名確認及法定代理人閱覽兒童個人信息等客戶支援;未滿18歲的青少年加入時，爲得到法定代理人同意的實名確認及遊戲使用明細通知',
  "(7) 휴대폰 소유정보, 신용카드 소유정보, 본인확인 서류: 가입 시 혹은 기타 개인정보 변경 요청 시 본인확인을 위한 인증수단":
    "(7) 手機所有信息、信用卡所有信息、本人確認文件：註冊時或其他個人信息變更請求時，爲確認是否是本人的認證手段",
  "제 5 조 조 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항":
    "第5條 個人信息自動收集裝置的設置、運營及其拒絕相關事項",
  "■ 쿠키란?": "■ 什麼是Cooki？",
  "특정 웹사이트에서 웹서버가 웹브라우저에게 전달하는 작은 텍스트 파일 서비스 개선을 위해 쿠키를 이용하여 이용자의 접속 기록, 이용 형태 등을 파악합니다.":
    "Cookie是將訪問網站時的使用履歷和輸入信息等保存在訪問者電腦中的結構。Cookie 允許您在下次訪問該網站時識別訪問者的電腦，並根據訪問情況提供信息。",
  "이용자는 브라우저에서 옵션 설정을 통해 쿠키가 수집되지 않도록 거부할 수 있습니다.":
    "用戶可以通過瀏覽器中的選項設置來拒絕收集 cookie。",
  " 고급": "高級",
  " 쿠키 그 외 브라우저의 경우 브라우저 별 설정 방식에 따릅니다.":
    "對於非 cookie 瀏覽器，請按照每個瀏覽器的設置方法進行操作。",
  "■ 웹로그 분석이란?": "■什麼是網絡日誌分析（使用狀況分析）？",
  "웹사이트상에서 이용자의 서비스 이용 형태에 대한 분석":
    "網站上的使用者的服務利用形態分析",
  "oogle Analytics와 같이 다양한 외부 웹로그분석 도구를 사용할 수 있으며 Google Analytics의 경우 데이터가 사용되는 것을 거부할 수 있습니다.":
    "可以使用多種外部網絡日誌分析工具（如 Google Analytics），或者使用 Google Analytics，可以拒絕使用數據。",
  "- Google Analytics 차단: https://tools.google.com/dlpage/gaoptout/ 그 외 웹로그분석 도구의 경우 도구 별 거부 방식에 따릅니다.":
    " - Google Analytics 拒絕:https://tools.google.com/dlpage/gaoptout 對於其他 Web 日誌分析工具，請按不同工具的拒絕方式操作。",
  "제 6 조 개인정보의 제공": "第6條 個人信息的提供",
  "1. 회사는 이용자의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 어떠한 경우에도 [개인정보의 수집목적 및 이용목적]에서 고지한 범위를 넘어 이용자의 개인정보를 이용하거나 타인 또는 타기업/기관에 제공하지 않습니다.":
    "1. 除非用戶同意或法令規定的情況外，本公司在任何情況下均不得超出[個人信息收集目的及使用目的]中告知的範圍，使用使用者的個人信息，或提供給第三方或其他企業機構。",
  "2. 이용자의 개인정보를 제공하는 경우에는 개인정보를 제공받는 자, 개인정보를 제공받는 자의 개인정보 이용 목적, 제공하는 개인정보 항목, 개인정보를 제공받는 자의 개인정보 보유 및 이용기간에 대해 개별적으로 인터넷사이트, 전자우편, 서면, 전화 등을 통해 고지한 후 이에 대한 사전 동의를 구합니다.다만, 다음의 경우에는 이용자의 동의 없이 개인정보를 제공할 수 있습니다.":
    "2. 提供用戶個人信息時，請通過網站、電子郵件、書面、電話等分別告知個人信息提供者、個人信息的使用目的、所提供的個人信息項目、個人信息提供者的個人信息的保有及使用期限後，事先徵得同意。但以下情況下無需用戶同意即可提供個人信息。",
  "(1) 서비스 제공에 따른 요금 정산을 위하여 필요한 경우":
    "(1) 結算提供服務時的費用時",
  "(2) 통계작성, 학술연구 또는 시장조사를 위하여 개인을 식별할 수 없는 형태로 제공하는 경우":
    "(2) 爲進行統計、學術研究或市場調查，以無法識別個人的形式進行提供時",
  "(3) 법령에 특별한 규정이 있는 경우": "（3） 法令有特別規定時",
  "3. 회사는 관계 법령에 의하거나 수사기관의 요청에 의해 이용자의 개인정보를 제공한 경우 이를 당사자에게 고지하는 것을 원칙으로 합니다. 단, 법률상의 근거에 의해 부득이하게 고지를 못할 수도 있습니다.":
    "3. 公司根據相關法律或根據調查機關的要求提供用戶個人信息時，原則上告知當事人。 但是，也有可能因爲法律規定，無法進行告知。",
  "4. 회사는 아래와 같은 경우 이용자의 동의 하에 개인정보를 제3자에게 제공할 수 있습니다.":
    "4. 公司可在用戶同意下向第三方提供個人信息。",
  "(1) 물품구매, 유료컨텐츠 이용 등의 배송 및 정산을 위해 이용자의 이름, 주소, 전화번호 등이 해당 쇼핑몰 업체, 유료컨텐츠 제공자, 배송업자에게 제공될 수 있습니다.":
    "（1）爲購買物品、使用付費內容等配送及結算，用戶的名字、地址、電話號碼等可提供給相關購物中心企業、付費內容提供者、配送業者。",
  "(2) 각종 이벤트 행사에 참여한 회원의 개인정보가 해당 이벤트의 주최자에게 제공될 수 있습니다.":
    "（2）參與各種活動活動的會員的個人信息可提供給該活動的主辦者。",
  "5. 회사는 이용자에게 제공되는 서비스의 질을 향상시키기 위해 맞춤 서비스, 온라인광고 서비스, 쇼핑몰 서비스, 커뮤니티 서비스, 유료컨텐츠 서비스, 모바일 서비스, 보험ㆍ신용카드 등의 텔레마케팅 서비스, 통계작성 또는 시장조사 등 다양한 서비스를 제공할 목적으로 여러 분야의 전문 컨텐츠 사업자 혹은 비즈니스 사업자와 함께 파트너쉽을 맺을 수 있습니다.":
    "5. 公司爲提高向用戶提供的服務質量，以提供定製服務、在線廣告服務、購物服務、社區服務、收費內容服務、移動服務、保險、信用卡等電話營銷服務、統計製作或市場調查等多種服務爲目的，可與各領域專業內容商或商務運營商建立夥伴關係。",
  "제 7 조 개인정보의 처리위탁": "第七條 個人信息的處理委託",
  "1. 회사는 제3자에게 이용자의 개인정보를 수집ㆍ보관ㆍ처리ㆍ이용ㆍ제공ㆍ관리ㆍ감독 및 교육ㆍ파기 등(이하 ":
    "1. 本公司委託第3者收集、保管、處理、利用、提供、管理、監督及教育、廢除等（以下稱",
  "라 한다)을 할 수 있도록 업무를 위탁(이하 ":
    '"處理"），以便進行業務委託（以下稱',
  "이라 한다)하는 경우에는 다음 각 호의 사항 모두를 이용자에게 알리고 동의를 받습니다.":
    '"個人信息處理委託"）時，將以下各號事項全部通知用戶，並徵得用戶的同意。',
  "(1) 개인정보 처리위탁을 받는 자(이하 라 한다)":
    '(1) 受委託處理個人情報的人員（以下稱"受理人"。）',
  "(2) 개인정보 처리위탁을 하는 업무의 내용":
    "(2) 委託進行個人信息處理的業務內容",
  "2. 회사는 이용자에게 다양하고 높은 수준의 서비스 제공을 위해 반드시 필요한 업무에 대해서는 아래와 같이 외부전문업체에 위탁하여 운영합니다.":
    "2. 爲用戶提供多種高質量服務，公司必不可少的業務應委託外部專業公司經營，如下所示:",
  수탁업체: "受託企業",
  위탁업무내용: "委託業務內容",
  "개인정보 이용기간": "個人信息利用期",
  "나이스신용평가정보㈜": "NICE信用評價信息（株）",
  "이용자 본인 확인": "用戶本人驗證",
  "별도 저장하지 않음": "不另行保存",
  KG이니시스: "KG INICIS ",
  "유료서비스 결제처리 및 요금정산": "收費服務結算處理及費用結算",
  "결제일 기준 5년": "以結算日爲準 5年",
  "제 8 조 이용자 및 법정대리인의 권리와 그 행사 방법":
    "第八條 用戶及法定代理人的權利及其行使方法",
  "1. 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수 있습니다.":
    "1. 用戶及法定代理人隨時可以查詢或修改已登記的個人信息，並可以要求註銷註冊。",
  "2. 이용자 혹은 만 14세 미만 아동의 개인정보 조회 및 수정을 위해서는 마이페이지의 ‘개인정보관리’를 가입해지를 위해서는 ‘회원탈퇴’ 메뉴를 이용해서 직접 열람, 정정 또는 탈퇴가 가능합니다. 또는 개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하도록 하겠습니다.":
    '2. 查詢、修改用戶或未滿15週歲兒童的個人信息時，可以在我的主頁中的"個人信息管理"進行，需要進行會員銷戶時，可通過"會員退會"菜單直接閱覽、修改、退會。或者通過書面、電話或電子郵件聯繫個人信息保護負責人，我們將立即採取措施。',
  "3. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리 결과를 제 3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.":
    "3. 用戶要求更正個人信息錯誤時，在更正完成之前，公司不會使用或者向第三方提供該個人信息。此外，如果錯誤的個人信息已經提供到第三方時，我們將及時通知第三方更正處理結果，並予以更正。",
  "4. 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 ‘개인정보의 보유기간 및 이용기간’에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.":
    '4. 本公司根據用戶或法定代理人的要求，對解除或刪除的個人信息，按照"個人信息的保留期限及使用期限"中明示的內容進行處理，除上述符合規定的用途外，不予提供閱覽或使用該個人信息。',
  "제 9 조 개인정보의 보유기간 및 이용기간":
    "第9條 個人信息的持有期間及使用期間",
  "1. 회사는 이용자가 이용자자격을 유지하고 있는 동안에는 이용자가 회사에 제공한 개인정보를 계속 보유합니다.":
    "1. 本公司在用戶保持用戶資格期間，將繼續保存用戶提供給本公司的個人信息。",
  "2. 개인정보제공에 대해 동의철회(회원탈퇴)할 경우 즉시 수집된 개인정보를 즉시 파기하며, 예외 규정을 제외하고 어떠한 목적으로도 사용할 수 없도록 합니다.":
    "2. 同意撤回個人信息提供（會員退會）時，應立即銷燬收集的個人信息，除例外規定外，任何目的均不得使用。",
  "3. 일시적인 목적에 의해 수집한 개인정보는 그 이용목적을 달성하면 지체 없이 파기합니다. 단, 이벤트응모를 위해 주소 등의 개인정보를 제공한 경우에는 이벤트상품 발송 및 이벤트응모 고객에 대한 고객응대를 위해 해당 이벤트의 종료시점으로부터 6개월간 해당 개인정보를 보유합니다.":
    "3. 因臨時目的收集的個人信息，在完成其使用目的後，應立即銷燬。但是，若用戶爲了參與有獎活動提供了地址等個人信息時，爲確保活動獎品可以順利送達至用戶，會將提供的個人信息保留至活動結束日開始的6個月內。",
  "4. 이용자가 본인이 회사의 이용자임을 증빙하기 위해 회사에 발송한 신분증 사본 등 서류일체는 본인확인 후 즉시 파기합니다.":
    "4. 爲證明用戶本人爲本公司用戶的目的，向本公司提供的身份證複印件等一應證明文件，在確認是否本人後，將立即銷燬。",
  "5. 법정대리인의 동의여부를 증빙하는 자료는 접수일로부터 최소 2년간 보유함을 원칙으로 하나, 법정대리인 동의 이후 2년 이내에 이용자가 회원탈퇴를 하는 경우에는 그 즉시 파기합니다.":
    "5. 證明法定代理人同意與否的資料，原則上應自受理之日起至少保存2年，但法定代理人同意後2年內用戶退會時，應立即銷燬。",
  "6. 『상법』 및 『전자상거래 등에서의 소비자보호에 관한 법률』등 관련법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 법령에서 정한 일정한 기간 동안 이용자정보를 보관합니다. 이 때 회사는 보관하는 정보를 그 보관 이외의 목적으로 사용할 수 없습니다. 구체적인 사항은 다음과 같습니다.":
    "6. 根據《商法》及《電子商務中的消費者保護相關法律》等相關法令的規定需要保存時，本公司將在法令規定的時間內保存使用者信息。此時，公司保管的信息不能用於保管以外的目的。",
  "제 10 조 개인정보 파기절차 및 방법": "第10條 個人信息銷燬程序及方法",
  "회사는 이용자에게 사전에 고지하고 동의 받은 개인정보의 수집ㆍ이용목적이 달성되면 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.":
    "本公司事先告知用戶，在獲得同意的個人信息收集、使用目的完成後，立即將其廢棄。廢棄的手續及方法如下。",
  "1. 파기절차": "1. 銷燬程序",
  "(1) 이용자가 입력한 개인정보는 수집ㆍ이용목적이 달성되면 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라":
    "(1) 用戶輸入的個人信息收集、使用目的完成時，將轉移至單獨DB（紙質文件箱），根據內部方針及其他相關法令的信息保護事由，",
  "(2) 제10조에 근거하여 일정기간 저장 후, 재생할 수 없는 방법으로 파기됩니다.":
    "(2) 根據第10條保存一定時間後，以無法再生的方法進行銷燬。",
  "(3) 별도 DB로 옮겨진 이용자의 개인정보는 법령에 의한 경우가 아닌 다른 목적으로 이용되지 않습니다.":
    "(3) 轉移到其他DB的使用者的個人信息，除法令規定的個人信息以外，不會用於其他目的。",
  "2. 파기방법": "2. 銷燬方法",
  "(1) 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.":
    "(1) 通過電子文件形態保存的個人信息，將通過技術手段，使其變成無法修復，無法可讀的文件。",
  "(2) 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.":
    "(2) 打印在紙張上的個人信息通過粉碎機或焚燒而被銷燬。",
  "제 11 조 개인정보보호를 위한 기술적ㆍ관리적 대책":
    "第11條 個人信息保護的技術層面、管理層面對策",
  "1. 이용자의 개인정보는 이용자 자신이 설정한 비밀번호에 의해 우선적으로 보호되고 있습니다. 따라서 이용자는 절대로 비밀번호를 타인에게 알려주거나 공유해선 안되며, 회사 서비스의 이용완료 후에는 반드시 로그아웃(LOG-OUT)을 이용하여 웹 브라우저를 종료하여야 합니다. 특히 다른 사람과 컴퓨터를 공유하여 사용하거나 공공장소에서 이용한 경우에는 타인에게 개인정보가 알려지는 것을 막기 위하여 위 절차는 반드시 필요합니다. 이외에도 회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 및 훼손되지 아니하도록 안전성 확보를 위하여 다음과 같은 기술적 내지 관리적 대책을 강구하고 있습니다.":
    "1. 利用用戶本人設定的密碼優先保護用戶的個人信息。因此，用戶絕對不能將密碼告知他人或共享，使用完公司服務後必須利用註銷（LOG-OUT）關閉網絡瀏覽器。特別是，如果用戶與他人共享計算機或在公共場所使用計算機，必須採取上述措施，以防止個人信息被他人所知。此外，公司還在處理用戶個人信息時，爲了確保個人信息不會丟失、盜竊、泄露、僞造、損壞，正在採取以下技術管理措施。",
  "2. 기술적 대책": "2. 技術對策",
  "(1) 이용자의 비밀번호는 암호화 처리되어 이용자 개개인의 비밀번호를 알 수 없도록 되어 있습니다.":
    "(1) 用戶的密碼經過加密處理 ， 無法知道每個用戶的密碼 。",
  "(2) 개인정보의 훼손에 대비하여 정보를 수시로 백업하고 있으며 최신의 백신프로그램을 이용하여 이용자의 개인정보와 자료가 누출되거나 손상되지 않도록 하고 있습니다.":
    "(2) 爲預防個人信息受損，隨時備份信息，利用最新的殺毒軟件，防止用戶的個人信息及資料泄漏、損壞。",
  "(3) 암호화 통신 등을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.":
    "(3) 通過加密通信等，可在網絡上安全地發送個人信息。",
  "(4) 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며 기타 시스템적으로 보완성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.":
    "(4) 通過防止入侵系統，管制來自外部的無端侵入，並努力具備其他確保系統完善性的所有可能的技術裝置。",
  "3. 관리적 대책": "3.管理對策",
  "(1) 개인정보보호책임자와 최소한으로 구성된 인원들에 대해서만 개인정보 관련 처리를 한정시키고 있으며 기타 인원에 대해서는 개인정보에 대한 접근권한을 제한하고 있습니다.":
    "(1) 個人信息相關處理，將限制由個人信息保護負責人以及最低配置的人員來進行，限制其他無關人員對個人信息進行訪問。",
  "(2) 개인정보처리인원에 대한 교육을 통하여 개인정보보호의무 등 관련된 의무를 항상 강조하고 있으며 개인정보처리방침이행사항 및 담당자의 준수 여부를 수시로 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 최선의 노력을 기울이고 있습니다.":
    "(2) 對個人信息處理人員進行嚴格培訓，一直在強調個人信息保護義務等相關責任與義務，並隨時監督相關人員是否嚴格遵照個人信息處理方針來履行義務，如發現不妥時，會及時對其進行更正。",
  "4. 회사는 이용자 스스로의 실수 또는 부주의 기타 기본적인 인터넷의 위험으로 인해 발생되는 개인정보유출 및 훼손에 대해서는 어떠한 책임도 부담하지 않습니다.":
    "4. 因用戶本人的錯誤或疏忽、或其他常見的網絡危險而發生的個人信息泄露及損壞，本公司不負任何責任。",
  "제 12 조 개인정보보호책임자 및 담당자의 연락처":
    "第12條 個人信息保護負責人及負責人的聯繫方式",
  "이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 회사는 개인정보보호책임자를 두고 있습니다. 개인정보와 관련된 문의사항이 있으시면 아래의 개인정보보호책임자에게 연락주시기 바랍니다. 문의하신 사항에 대해서 신속하고 성실하게 답변해드리겠습니다":
    "爲保護用戶的個人信息，並處理有關個人信息的投訴，本公司設有個人信息保護負責人。有關個人信息的諮詢，請聯繫以下個人信息保護負責人。對於您提出的問題，我們將迅速、誠實地進行回答。",
  "[개인정보보호 책임자]": "[個人信息保護負責人]",
  "이름 : 김재우": "姓名:金載佑",
  "소속 : ㈜팡스카이 이사": "職位:（株）Pangsky 理事",
  "[개인정보관리 담당부서]": "[個人信息管理部門]",
  "부서 : 서비스운영팀": "部門：服務運營組",
  "전화번호 : 070-4658-9412": "電話號碼：070-4658-9412",
  "제 13 조 청소년보호를 위한 활동": "第12條 青少年保護措施",
  "회사는 각종 청소년 유해정보로부터 청소년을 보호하고자 관련법에 따라 만 19세미만의 청소년이 유해정보에 접근할 수 없도록 청소년보호정책을 마련하여 시행하고 있습니다. 또 회사는 청소년의 건전한 성장을 저해하는 음란, 불법 유해정보와 비윤리적, 반사회적 행위에 대해서는 엄격하게 제재하기 위하여 다음과 같이 활동하고 있습니다.":
    "本公司爲防止青少年過早接觸各種有害信息，將依據相關法律，對未滿19週歲的用戶，採取不可接觸有害信息的政策。並且，本公司爲了青少年健康的精神成長，嚴格抵制淫亂、違法、有悖倫理、反社會等有害行爲。併爲此付諸如下舉措。",
  "1. 청소년유해정보로부터의 청소년보호계획의 수립":
    "1. 制定使青少年遠離危害青少年身心健康的信息的保護計劃。",
  "2. 청소년유해정보에 대한 청소년 접근제한 및 관리조치":
    "2. 限制並監督青少年不得接近有害身心健康的信息。",
  "3. 정보통신업무 종사자에 대한 청소년유해정보로부터의 청소년보호를 위한 교육":
    "3. 對信息通信業務從事者，進行怎樣在青少年有害信息中保護青少年的教育",
  "4. 청소년유해정보로 인한 피해상담 및 고충처리":
    "4.青少年有害信息引起的受害諮詢及煩惱處理",
  "5. 그 밖에 청소년유해정보로부터 청소년을 보호하기 위하여 필요한 사항":
    "5. 此外，從青少年有害信息中保護青少年的必要事項",
  "제 14 조 개인정보 관련 신고 및 분쟁조정":
    "第14條 個人信息相關申報及糾紛調解",
  "개인정보에 대한 상담이 필요한 경우에는 다음의 곳으로 문의하시길 바랍니다.":
    "需要諮詢個人信息時，請參考如下聯繫方式。",
  "- 개인정보침해신고센터 전화: 1336, 홈페이지: www.cyberprivacy.or.kr":
    "- 個人信息侵害舉報中心電話:1336，網站:www.cyberprivacy.or.kr",
  "- 개인정보분쟁조정위원회 전화: 02-405-4747, 홈페이지: www.kopico.or.kr":
    " - 個人信息糾紛調解委員會電話:02-405-4747，網址:www.kopico.or.kr",
  "#NAME?": "#NAME?",
  "- 경찰청 사이버테러대응센터 홈페이지: www.ctrc.go.kr":
    " - 警察廳網絡恐怖襲擊應對中心主頁:www.ctrc.go.kr",
  "제 15 조 정책 변경에 따른 공지의무": "第十五條 政策變更的公告義務",
  "법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 시에는 변경되는 개인정보처리방침을 시행하기 최소 7일전에 서비스 초기화면을 통해 변경이유 및 내용 등을 공지하도록 하겠습니다.":
    "根據法令政策或保安技術的變更，如有追加刪除或修改內容，則需在實施變更的個人信息處理方針前至少7天前，通過服務首頁公告變更理由及內容等。",
  부칙: "附則",
  "본 개인정보처리방침은 2017년12월07일부터 시행됩니다.":
    "本個人信息處理方針自2017年12月07日起施行。",
  "2016년 05월 09일부터 시행되던 종전의 정책은 본 정책으로 대체합니다.":
    "從2016年05月09日起施行的舊政策將改爲本政策。",
  "안녕하세요. 팡스카이입니다.": "您好，這裏是Pangsky。",
  "팡스카이는 게임 퍼블리싱 서비스를 기반으로": "PANGSKY是以遊戲發行服務爲基礎",
  "한국을 포함한 전 세계에 특별한 재미와 경험을 제공하는 기업입니다.":
    "爲包括韓國在內的全世界廣大用戶，提供特別樂趣和經歷爲宗旨的企業。",
  "창의적인 상상력을 바탕으로 온라인게임과 모바일게임, ":
    "以充滿創意的想象力爲基礎， ",
  "그리고 콘텐츠 사업으로 영역을 확장하고 있으며,":
    "正向着網絡遊戲、手機遊戲、文化產品領域拓展，",
  "새로운 도전을 통해 글로벌 고객에게 즐거움을 선사할 것입니다.":
    "並通過不間斷地對新領域的挑戰，立志爲全世界的顧客帶來樂趣。",
  "게임퍼블리싱을 위한": "爲遊戲發行準備的",
  "해외 네트워크": "海外合作網絡",
  "중국의 바이두를 비롯하여 약 40여 게임관련":
    "包括中國的百度在內的，與約40多家遊戲相關的",
  "기업들과 긴밀한 협력 네트워크를 구축 완료": "企業形成了緊密合作的關係網路",
  "게임 확보 여력": "獲得遊戲授權的履歷",
  "해외에서 성공 케이스를 가진": "諸多在海外市場有成功案例的",
  "중국 개발 게임들에 관한 판권 확보 가능":
    "中國研發公司研發的產品的發行版權，都可獲取",
  "국내 네트워크": "國內合作網絡",
  "팡스카이의 창업 멤버들은 한국 게임 업계 평균 19년":
    "Pangsky的創始成員均在韓國遊戲業界從事了19年以上",
  "경력을 가지고 있으며, 다양한 인맥 및 리소스 네트워크를 보유":
    "的資深遊戲人，並用有龐大的業界人脈和關係網",
  "안정적인 운영시스템": "穩定的運營體系",
  "게임 퍼블리싱 업무 진행 경험을 바탕으로": "以往屆遊戲發行經驗爲依據",
  "뛰어난 리스크 관리 및 빠른 업무처리 및":
    "出色的風險管理及迅速的業務執行力以及",
  "Big Data 연구소를 보유하여 체계적 마케팅 가능":
    "擁有大數據研究所，提供全方位的市場營銷",
  " ‘묵혼’ 태국 출시": "墨魂OL 泰國上線",
  " ‘RF 온라인’ 일본 서비스": "RF OL 日本上線",
  " ‘포트리스 배틀로얄‘ 서비스 출시": "瘋狂坦克 手遊 全面上線",
  "‘묵혼’ 온라인 대만 출시": "墨魂OL 臺灣上線",
  " ‘고수’ 대만 출시": "高手 手遊 臺灣上線",
  " ‘묵혼’ 온라인 한국 출시": "墨魂 OL 韓國上線",
  "‘고수‘ 모바일 한국 출시": "高手 手遊 韓國上線",
  " 온라인 SF MMORPG ‘RF온라인’ 서비스 출시": "RF OL 日本上市",
  " 씨씨알컨텐츠트리와 ‘포트리스 2M’ 퍼블리싱 계약 체결":
    '與韓國CCR INC簽訂"瘋狂坦克2" 手遊發行協議 ',
  " 모바일 MMORPG ‘무협왕’ 앱 스토어 출시": '手遊 "刺秦" 韓國APP STORE上線',
  " 모바일 MMORPG ‘리그너스 대륙전기‘ 구글 플레이, 원 스토어 출시":
    '手遊 "主宰之王" 韓國Google play（韓國）、One Store（韓國）上線',
  "모바일 SRPG ‘삼국 어웨이큰＇구글 플레이, 원 스토어 출시":
    '手遊"三國演義"  韓國Google play（韓國）、One Store（韓國）上線',
  " 모바일 MMORPG ‘십만대적검 : 리부트‘출시":
    ' 手遊 "蠻荒天下"韓國Google play（韓國）、One Store（韓國）上線',
  " 모바일 MMORPG ‘백가쟁패’ 구글 플레이, 원 스토어 출시":
    ' 手遊 "百家爭覇"韓國Google play（韓國）、One Store（韓國）上線',
  "모바일 MMORPG ‘신의 아이들‘구글 플레이, 앱 스토어 ,원 스토어 출시":
    '手遊 "神之子" 韓國Google play（韓國）、One Store（韓國）上線',
  " 모바일 MMORPG ‘십만대적검’ 구글 플레이, 원 스토어 출시":
    '手遊 "蠻荒" 韓國Google play（韓國）、One Store（韓國）上線',
  " 모바일 MMORPG ‘여신전기‘ 구글 플레이, 원 스토어 출시":
    ' 手遊"龍騎戰歌" 韓國Google play（韓國）、One Store（韓國）上線',
  " 모바일 무협 MMORPG ‘군도‘ 구글 플레이, 원 스토어 출시":
    '手遊"醉美人" 韓國Google play（韓國）、One Store（韓國）上線',
  " 웹 MMORPG ‘촉산협객전‘ 서비스 출시": '頁遊"蜀山外傳"韓國上線',
  " 블루사이드와 ‘킹덤언더파이어‘ 모바일 게임 개발 업무 협약 체결":
    "與韓國BLUESIDE廠商，簽訂“熾焰帝國”手遊開發合約",
  " 모바일 게임 ‘형님 모바일’ 구글 플레이 무협모바일게임 장르 1위 달성":
    "手遊“劍雨情緣”韓國谷歌武俠類暢銷榜第一名",
  " 웹 무협 MMORPG ‘검우강호’ 사전 등록자 15만명 기록":
    "頁遊“劍雨江湖”事前登錄者達到15萬人",
  " 웹게임 ‘무쌍 온라인‘ 서비스 출시": "頁遊“怒斬千軍”韓國上線",
  "㈜팡게임, ‘㈜팡스카이’로 사명 변경":
    "公司名稱由(株)Panggame變更爲(株)Pansky",
  " 모바일 해적 전략시물레이션 게임 ‘무적함대1942’ 구글 플레이 출시":
    "海戰策略手遊“無敵艦隊1942”谷歌上線",
  " 모바일 MMOARPG ‘칼에 깃든 바람‘ 원 스토어 출시":
    'MMOARPG手遊"風之刃“ One Store上線',
  " 모바일게임 ‘대황제M’ 구글 플레이,원 스토어 출시, 구글 플레이 전략게임 1위 달성":
    "手遊“大皇帝M” 韓國Google play（韓國）、One Store（韓國）上線，並在韓國谷歌戰略遊戲類暢銷榜第一名",
  " 무협 웹게임 ‘운중가온라인‘ 서비스 오픈": '頁遊"雲中歌"韓國上線',
  " PlamJoy와 웹게임 ‘운중가온라인’ 한국 퍼블리싱 계약 체결":
    "與PlamJoy簽訂頁遊“雲中歌OL”韓國發行授權協議",
  " PC온라인게임 ‘대황제’ 네이버 등 8개사 채널링 계약":
    "PC網遊“大皇帝”與NAVER等8個渠道商簽訂發行協議",
  "모바일게임 ‘바다속이야기’ 구글 플레이, N스토어 출시":
    "手遊“大海的故事”韓國Google Play, N Store上線",
  " PC온라인게임 ‘데빌에이지‘ 서비스 오픈": "PC網遊“魔鬼時代”韓國上線",
  " 벤처기업확인(벤처기업협회)": "創業企業認證(創業企業協會)",
  " PC온라인게임 “뉴던전히어로” 오픈": "PC網遊“新地下城英雄”韓國上線",
  " ‘기업부설연구소 설립(미래창조과학부)":
    "成立企業附屬研究所（未來創造科學部）",
  " 중국 최대 검색포털 ‘바이두‘의 “바이두모바일게임＂과 협력체결":
    "與中國最大的搜索平臺”百度移動”達成 合作協議",
  "모바일 게임 “구몽:전생의기억“  구글 플레이 출시":
    '手遊"六界仙尊" 韓國Google play（韓國）',
  " 모바일 게임 “케로로 액션히어로 for Kakao 구글 플레이, 앱 스토어 ,원 스토어 출시":
    '手遊"Keroro軍曹" 韓國Google play（韓國）、One Store（韓國）上線',
  " 국내 모바일게임사 서비스팀 인수": " 收購手遊運營團隊",
  " PC온라인게임 “구몽온라인“ 6개 채널링 동시오픈":
    "“六界仙尊”在6個遊戲平臺同時上",
  " 중국 ‘광주명조네트워크’와 PC온라인게임 “구몽온라인“ 한국 독점 서비스 계약체결":
    "與中國廣州明朝網絡簽訂“六界仙尊”韓國獨代協議",
  " ‘㈜투윈게임즈’와 PC온라인게임 “원더킹“ 채널링 계약 체결":
    "與(株)2WIN GAMES簽訂“WONDER KING”獨代協議",
  " ‘㈜이야소프트’와 PC온라인게임 “에다전설“ 국내 서비스 계약 체결":
    "與(株)Eyasoft簽訂“艾達傳說”獨代協議",
  " 중국 ‘자모조이’와 모바일게임 “스와트앤좀비” 서비스 계약 체결":
    " 與中國嘉淼科技簽訂“SWAT&ZOMBIES”獨代協議",
  "‘㈜미디어로그’와 “스와트앤좀비“ 해외 서비스 계약 체결":
    " 與(株)Medialog簽訂“SWAT&ZOMBIES”海外獨代協議",
  " www.panggame.com 사이트 오픈": "www.panggame.com遊戲平臺開放",
  " 주식회사 팡 게임 설립": "(株)PangGame成立",
  "‘㈜더파이널파이브’와 PC온라인게임 ‘’와인드업‘ 서비스 계약 체결":
    " 與(株)The Final Five簽訂PC網遊“Wind Up”獨代協議",
  "팡스카이가 걸어온 길": "Pangsky公司歷程",
  "전화 : ": "TEL：",
  "팩스 : ": "Fax：",
  "서울특별시 강남구 논현로80길 25":
    "20, Digital-ro 31-gil, Guro-gu, Seoul, Republic of Korea",
  "팡스카이와 함께 꿈을 만들어 보실래요?": "想不想和Pangsky一起打造一個夢境？",
  "지원하러 가기": "投遞簡歷",
  "No tie! 편안한 복장": "拒絕正裝領帶！便裝即可",
  "자율 복장으로 출퇴근길을 가볍게~!": "穿着自己想穿的衣服，輕鬆上班~",
  "사내 카페테리아 운영": "公司內置自助餐廳",
  "카페테리아, 미니 피트니스 등 휴게 공간": "自助餐廳，迷你健身房等休閒設施",
  "해피 급여 데이": "快樂領薪日",
  "급여일에 한해 점심시간 30분 연장": "薪資日享受額外的30分鐘午休時間",
  "명절 선물 지급": "節日賀禮",
  "설,추석 명절에 백화점 상품권 지급": "春節，中秋佳節贈送商場代金券",
  "자율 연차제": "年假自由制度",
  "근로기준법에 따른 연차 제도 실시":
    "根據勞動基準法的規定，職工可酌情自由使用年假",
  "시차 출퇴근제": "彈性通勤制度",
  "출근10:00 ~ 10:30 | 퇴근 19:00 ~ 19:30":
    "上班時間10:00~10:30 | 下班時間19:00~19:30",
  "야간 근무자 지원": "加班補助費",
  "야근 식대, 야근 택시비": "加班餐費，加班車費",
  "각종 경조사 지원": "各種紅白喜事補助",
  "경조금 및 경조 휴가": "紅白喜事補貼&休假",
  패밀리데이: "美滿家庭日",
  "매월 셋째주 금요일 17시 조기 퇴근": "每月第三週的週五，17點即可提前下班",
  "인재 추천 보상금": "人才推薦獎金",
  "추천 인재 정규직 전환 시 추천자에게 직급에 따른 보상 지급":
    "推薦的人才轉正時，根據被推薦人的職位發放相應獎金",
  "회식비 지원": "會餐補助",
  "팀원 단합을 위한 월 단위 회식비 지원":
    "爲了職工們的團結，以月爲單位支援會餐費",
  "생일 축하 및 선물 지급": "生日賀禮",
  "백화점 상품권 및 유급 휴가 0.5일": "商場代金券以及帶薪半天假",
  소개: "簡介",
  팡스카이소개: "PANGSKY 簡介",
  게임: "遊戲",
  미디어: "媒體",
  보도자료: "報導資料",
  "미디어 행사": "媒體活動",
  문화: "文化",
  기업문화: "企業文化",
  복지: "福利",
  "팡스카이 소식": "PANGSKY NEWS",
  채용: "招聘",
  "채용 소개": "招聘簡介",
  투자정보: "投資信息",
  기업지배구조: "企業管理結構",
  "광고/제휴 문의": "廣告、合作諮詢",
  "기업윤리 신고센터": "企業倫理投訴中心",
  "개인정보 처리방침": "個人信息處理方針",
  "팡스카이 스토어": "Pangsky Store",
  "게임 고객센터": "遊戲客服中心",
  "광고/제휴 문의 안내": "廣告、合作諮詢說明",
  "팡 스카이는 보다 더 투명하고": "Pangsky爲了樹立更加透明，",
  "윤리적인 기업문화의 정착을 위해 기업 윤리 신고센터를 운영합니다.":
    "更加符合倫理的企業文化，特設立有企業倫理投訴中心。",
  "임직원의 불공정한 업무수행,금품,접대 등 부당한 요구 및 업무 중 부당한 회계처리":
    "所有職員可以對不公平的業務指派、要求金錢、招待等不當要求以及工作相關不當的財務處理",
  "기타 부정,비리 행위를 상담, 신고 할 수 있습니다.":
    "和其他不當待遇進行投訴。",
  "임직원,협력사, 기타 제보와 관련있는 거래 당사자, 제 3자 등":
    "職員、合作公司及其他與信息提供有關交易當事者、第三方等",
  "그 누구라도 실명 또는 익명으로 신고 가능하며, 신고된 내용은":
    "都可以用實名或匿名進行投訴，投訴內容",
  "팡스카이 감사 주관하에 공정하고 엄중하게 조사 처리 됩니다.":
    "會在Pangsky內部監察部門的監督下，以公平公正的原則進行處理。",
  "제보자의 신분과 상담,신고 내용 등은 철저히 비밀로 보장되며,":
    "信息提供者的身份和諮詢、申報內容都將會進行嚴格保密。",
  "제보자는 어떠한 경우에도 정당한 제보를 이유로":
    "投訴人在任何情況下，都不應該因使用正當的投訴權利，",
  "불이익이나 처벌을 받지 않음을 약속 드립니다.":
    "而去遭受不當的待遇以及處罰。",
  "접수 시 빠른 시일 내에 처리 할 수 있도록 최선을 다하겠습니다.":
    "投訴一經接受，我們會盡力在最快時間內進行處理。",
  "상담·신고하기": "諮詢&投訴",
  "처리결과 확인하기": "查看處理結果",
  "게임 고객센터: panggamehelp@panggame.com":
    "遊戲客服中心：panggamehelp@panggame.com",
  "(주)팡스카이 대표이사 지헌민, 대표이사 조호현, 사업자등록번호 : 119-86-62250, 통신판매업 신고번호 : 제 2014-서울강남-01064호":
    "（株）pangsky|代表理事李秉進，代表理事趙浩鉉|營業執照號碼:119-86-62250|通信銷售業申報號碼: 2022-首爾九老-2036",
  "주소 : 서울시 강남구 논현로80길 25(역삼동,정우빌딩), 전화 : 070-4658-9400, 팩스 : 070-8233-9383":
    "地址：Room 1201, 20, Digital-ro 31-gil, Guro-gu, Seoul, Republic of Korea｜TEL：+82 70-4658-9400｜FAX：+82 70-8233-9383",
  "2. 일부 유료 서비스 이용 시 유료 결제를 위해 수집하는 결제정보 별 수집목적은 다음과 같습니다.":
    "2. 使用部分收費服務時，爲了收費結算而收集的結算信息收集目的如下。",
  "(1) 은행계좌정보, 신용카드정보, 핸드폰번호, 전화번호: 유료정보이용에 대한 과금":
    "(1)銀行賬戶信息、信用卡信息、移動電話號碼、電話號碼:對利用收費信息收費",
  "- Internet Explorer: 도구 > 인터넷 옵션 > 개인정보 > 고급":
    " - Internet Explorer：工具 > 網絡環境設置 > 個人信息 > 高級",
  "- Chrome: 설정 > 설정 표시 > 개인정보의 콘텐츠 설정 버튼 > 쿠키 그 외 브라우저의 경우 브라우저 별 설정 방식에 따릅니다.":
    " - Chrome:設置 > 高級 > 內容設置 > Cookie 對於其他瀏覽器，請遵循每個瀏覽器的設置方法。",
  '1. 회사는 제3자에게 이용자의 개인정보를 수집ㆍ보관ㆍ처리ㆍ이용ㆍ제공ㆍ관리ㆍ감독 및 교육ㆍ파기 등(이하 "처리"라 한다)을 할 수 있도록 업무를 위탁(이하 "개인정보 처리위탁"이라 한다)하는 경우에는 다음 각 호의 사항 모두를 이용자에게 알리고 동의를 받습니다.':
    '1.公司委託第三方收集、保管、處理、使用、提供、管理、監督及教育、廢除等（以下稱"處理"）業務時，將下列各號事項全部告知用戶，並徵得用戶的同意。',
  '(1) 개인정보 처리위탁을 받는 자(이하 "수탁자"라 한다)':
    '(1) 個人信息的處理受理認(以下簡稱 "受理人")',
  "(1) 개인정보 처리위탁을 받는 자(이하 ": "(1) 個人信息的處理受理認(以下簡稱 ",
  "라 한다)": '"受理人")',
  "(1) 계약 또는 청약철회 등에 관한 기록": "合同或取消認購等相關記錄",
  "보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률":
    "保存理由:電子商務等消費者保護相關法律",
  "보존 기간 : 5년": "保存期限：5年",
  "(2) 대금결제 및 재화 등의 공급에 관한 기록":
    "(二)貨款結算及財物供應等相關記錄",
  "(3) 소비자의 불만 또는 분쟁처리에 관한 기록":
    "(3) 消費者投訴或糾紛處理相關記錄",
  "보존 기간 : 3년": "保存期限：3年",
  "(4) 전자금융 거래에 관한 기록": "(4)電子金融交易記錄",
  "보존 이유 : 전자금융거래법": "保存理由:電子金融交易法",
  "(5) 표시/광고에 관한 기록": "(5) 標識/廣告相關記錄",
  "보존 기간 : 6개월": "保存期限：6個月",
  "(6) 본인 확인에 관한 기록": "(6) 實名認證相關記錄",
  "보존 이유 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률":
    "保存理由:信息通信網利用促進及信息保護等相關法律",
  "(7)서비스 이용 관련 개인 정보(접속기록, 이용기록, IP정보)":
    "(7)服務利用相關個人信息（連接記錄、使用記錄、IP信息）",
  "보존 이유 : 통신비밀보호법": "保存理由:通信隱私保護法",
  "보존 기간 : 3개월": "保存期限：3個月",
  개인정보처리방침: "個人信息處理方針",
  "회사 소개 영상": "公司介紹視頻",
  "서울시 강남구 논현로80길 25(역삼동,정우빌딩)":
    "20, Digital-ro 31-gil, Guro-gu, Seoul, <br/>Republic of Korea",
  조직도: "組織結構圖",
  "(주)팡스카이 대표이사 지헌민, 대표이사 조호현    서울시 강남구 논현로 80길 25    전화:070-4658-9412    팩스:070-8233-9383":
    "（株）pangsky 代表理事池憲珉 首爾市江南區Nonhyun-ro 80-25 TEL:070-4658-9412    FAX:070-8233-9383",
  "사업자등록번호:119-86-62250호    통신판매업 신고번호 제2014-서울강남-01064호":
    "營業執照號:119-86-62250號 通信銷售業申報號 2014-首爾江南-01064號",
  "기업소식": "企業新聞",
  "공고": "公告"
};
