<template>
  <div id="app">
    <Header v-if="$route.meta.headerShow"></Header>
    <HeaderSecond v-if="!$route.meta.headerShow"></HeaderSecond>
    <router-view />
    <Footer></Footer>
    <GoTop></GoTop>
  </div>
</template>

<script>
/* 头部组件 */
import Header from "./components/Header";
import HeaderSecond from "./components/HeaderSecond";
/* 尾部组件 */
import Footer from "./components/Footer";
/* 回到顶部 */
import GoTop from "./components/GoTop";

export default {
  name: "App",
  components: {
    Header,
    HeaderSecond,
    Footer,
    GoTop
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="scss">
@media only screen and (max-width: 767px) {
  #app {
    width: 100vw;
    overflow: hidden;
  }
}
html {
  touch-action: none;
  touch-action: pan-y;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1204px;
  }
}
.swiper-pagination-bullet-active {
  background: #000 !important;
}
.detail .detail-content img {
  max-width: 100% !important;
}
.el-message {
  min-width: 250px !important;
}
</style>
