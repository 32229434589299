<template>
<div :style="sliderStyle">
  <div id="GoTop" @click="GoTop()">
    <button type="button" class="topbtn el-carousel__arrow el-carousel__arrow--up">
      <i class="el-icon-top"></i>
    </button>
  </div>
</div>
</template>
<script>
export default {
  name: "GoTop",
  data() {
    return {
      flag: false,
      fullWidth: 0, 
			sliderStyle:{
					width:''
			},
    };
  },
  mounted(){
    this.handleResize()
  },
  methods: {
    GoTop() {
      (function smoothscroll() {
        var currentScroll =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll - currentScroll / 10);
        }
      })();
    },
    handleResize (event) {
      this.fullWidth = document.documentElement.clientWidth;
      this.sliderStyle.width = this.fullWidth+'px'
    },
  },
  created(){
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
};
</script>
<style scoped>
#GoTop {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99999999;
  cursor: pointer;
}
.topbtn{
  background-color: transparent !important;
  border: 1px solid #333;
  height: 50px;
  width: 50px;
  
}
.topbtn .el-icon-top{
  font-size: 20px;
}
.topbtn .el-icon-top:before{
  color: #333;
}
.topbtn:hover{
  background-color: rgba(180, 180, 180, 0.5) !important;
  
}
@media screen and (max-width: 768px){
  .el-carousel__arrow {
      display: block !important;
  }
}
</style>
