<template>
    <div @click="clickCancel" class="confirmBgc animations" :style="{zIndex:rytzIndex}" v-show="show">
        <button class="rytContainer_btn">
            <div class="rytContainer" @click.stop="doNothing()">
                <div class="rytContent parent">
                    <!-- <slot name="content"></slot> -->
                    <div class="dialog-content" v-if="type==0">
                        <p class="title">{{ $t('광고/제휴 문의 안내') }}</p>
                        <div class="email-content">
                            <p v-html="emailcont[`content_${$i18n.locale}`]"></p>
                        </div>
                    </div>
                    <!-- 1 -->
                    <div class="dialog-content" v-if="type==1">
                        <p class="title">{{ $t('기업윤리 신고센터') }}</p>
                        <div class="content-text">
                            <p>{{ $t('팡 스카이는 보다 더 투명하고') }}</p>
                            <p>{{ $t('윤리적인 기업문화의 정착을 위해 기업 윤리 신고센터를 운영합니다.') }}</p>
                            <p>{{ $t('임직원의 불공정한 업무수행,금품,접대 등 부당한 요구 및 업무 중 부당한 회계처리') }}</p>
                            <p>{{ $t('기타 부정,비리 행위를 상담, 신고 할 수 있습니다.') }}</p>
                            <p>{{ $t('임직원,협력사, 기타 제보와 관련있는 거래 당사자, 제 3자 등') }}</p>
                            <p>{{ $t('그 누구라도 실명 또는 익명으로 신고 가능하며, 신고된 내용은') }}</p>
                            <p>{{ $t('팡스카이 감사 주관하에 공정하고 엄중하게 조사 처리 됩니다.') }}</p>
                            <p>{{ $t('제보자의 신분과 상담,신고 내용 등은 철저히 비밀로 보장되며,') }}</p>
                            <p>{{ $t('제보자는 어떠한 경우에도 정당한 제보를 이유로') }}</p>
                            <p>{{ $t('불이익이나 처벌을 받지 않음을 약속 드립니다.') }}</p>
                            <p>{{ $t('접수 시 빠른 시일 내에 처리 할 수 있도록 최선을 다하겠습니다.') }}</p>
                            <div class="btn-wrap">
                            <button type="button">{{ $t('상담·신고하기') }}</button>
                            <button type="button">{{ $t('처리결과 확인하기') }}</button>
                            </div>
                        </div>         
                    </div>
                    <!-- 1 -->
                    <!-- 2 -->
                    <div class="dialog-content" v-if="type==2">
                        <p class="title">{{ $t('게임 고객센터') }}</p>
                        <div class="content-text">
                            <p>{{ $t('게임 고객센터: panggamehelp@panggame.com') }}</p>
                        </div>
                    </div>
                    <!-- 2 -->
                </div>
                <div class="rytButtons">
                    <button type="button" class="btn-close" @click="clickCancel"></button>
                </div>
            </div>
        </button>
    </div>
</template>


<script>
import request from "../api/request"
export default {
    name:'modalConfirm',
    props:{
    type:Number,
    rytzIndex:{
        type:String,
        default:'998'
    },//层级
    rytWidth:{
        type:String,
        default:'300px'
    },//弹窗宽
    title:{
        type:[String,Boolean],
        default:'rrr'
    },//标题
    cancel:{
        type:[String],
        default:'取消'
    },//取消按钮
    confirm:{
        type:[String],
        default:'确定'
    },//确定按钮
    showModalconfirm:{
        type:Boolean,
        default:false
    },//显示
    isBtn:{
        type:Boolean,
        default:true
    },//是否显示按钮
    },
    data(){
        return{
            show:false,
            emailcont:{}
        }
    },
    mounted(){
        this.getemail();
    },
    methods:{
        //关闭弹窗
        doNothing () {},
        clickCancel(){
            this.show = false
        },
        //点击确定事件
        clickConfirm(){
            // this.show = false
            this.$emit('oncilckConfirm')
        },
        //点击确定事件后关闭弹窗
        isshow(){
            this.show = false
        },
        getemail(){
            let _this = this
            request.get('/news/421', {
                news_type__in:'6'
                }).then(function (res) {
                if (res.status == 200) {
                  _this.emailcont = res.data
                }
            })
        }
    },
    watch:{
        showModalconfirm(val){
            this.show = val
        },
        show(val){  //监听show的值，当show的值发生改变的时候val即为当前show的值，把该值传给父组件的v-model属性（v-                                 model绑定的是input事件，出发Input事件）
            this.$emit('input',val)
        }
    }
}
</script>
<style>
.parent{
    position: relative;
}
.animations {
    /* -webkit-animation: 'scaleDraw'  1s ease-in-out 0s 1 alternate forwards; 关键帧名称 */
    animation: 'scaleDraw' .6s ease-in-out 0s 1 alternate forwards;
}
.confirmBgc{
    position:fixed;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    overflow: auto;
    background-color: rgba(0,0,0,0.6);
}
.rytContainer_btn{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0);
    border: none;
    text-align: left;
}
.rytContainer{
    background:rgba(255,255,255,1);
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 740px;
    padding: 18px 40px 20px 40px;
    background: #fff;
    box-sizing: border-box;
}

.rytContent{
    font-size: 16px;
    color:#333;
    box-sizing: border-box;
    padding: 5px 30px 0 30px;
}

.rytButtons .btn-close{
    position: absolute;
    right: 20px;
    top: 18px;
    display: block;
    width: 33px;
    height: 33px;
    border: none;
    background: transparent;
    cursor: pointer;
}
.rytButtons .btn-close:after {
    transform: rotate(-45deg);
}
.rytButtons .btn-close:before {
    transform: rotate(45deg);
}
.rytButtons .btn-close:after, .rytButtons .btn-close:before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    border-right: solid 1px #000;
    top: 0;
    left: 16px;
}
.dialog-content .title{
  text-align: center;
  display: block;
  margin-bottom: 20px;
  padding: 0 30px 20px 30px;
  font-size: 24px;
  border-bottom: 2px solid #171919;
}
.dialog-content .content-text{
  text-align: center;
  margin: 20px 0 0 0;
  font-size: 14px;
  padding: 14px 0 20px 0;
  box-sizing: border-box;
}
.dialog-content .content-text p{
  margin: 0;
}
.dialog-content .email-content{
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
}
.dialog-content .btn-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0 0;
  overflow: hidden;
  text-align: center;
}
.dialog-content .btn-wrap button{
  display: inline-block;
  width: 200px;
  height: 60px;
  color: #fff;
  margin-right: 5px;
  border: none;
}
.dialog-content .btn-wrap button:nth-child(1){
  background: #345bad;
}
.dialog-content .btn-wrap button:nth-child(2){
  background: #000;
}

@media only screen and (max-width:900px) {
    .rytContainer {
        width: 90% !important;
       
    }
    .rytContent{
        padding: 0 10px 0 10px;
    }
    .dialog-content .content-text{
        overflow: hidden !important;
        overflow-y: scroll !important;
        max-height:400px !important;
    }
}
</style>
