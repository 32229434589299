import axios from 'axios'
import main from '@/main'
import { Message } from 'element-ui'

axios.defaults.withCredentials=true
axios.defaults.crossDomain=true
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// 根据 VUE_APP_BASE_URL 生成 baseUrl
let baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : 'http://localhost:8000/'
let staticUrl = process.env.VUE_APP_BASE_URL ? 'https://testkr.panggame.com' : 'http://localhost:8000'
if (!baseUrl.match(/^https?/i)) {
  baseUrl = `${window.location.protocol}//${window.location.host}${process.env.VUE_APP_BASE_URL}`
}

// 如果 Docker 中设置了 CRAWLAB_API_ADDRESS 这个环境变量，则会将 baseUrl 覆盖
const CRAWLAB_API_ADDRESS = '###CRAWLAB_API_ADDRESS###'
if (!CRAWLAB_API_ADDRESS.match('CRAWLAB_API_ADDRESS')) {
  baseUrl = CRAWLAB_API_ADDRESS
}

const local_mappings = {
    en: '',
    ko: 'ko/',
    cn: 'zh-hans/',
    tw: 'zh-hant/',
    jp: 'ja/',
}

const getBaseUrl = () => {
    let locale = 'en'
    if (main) {
        locale = main.app.$i18n.locale
    }
    return `${baseUrl}${local_mappings[locale]}`
}

const request = (method, path, params, data, _this, others = {}) => {
  const url = `${getBaseUrl()}1${path}`
  const headers = {
    'Authorization': 'Bearer ' + window.localStorage.getItem('token')
  }
  return axios({
    method,
    url,
    params,
    data,
    headers,
    ...others
  }).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response)
    }
    return Promise.reject(response)
  }).catch((e) => {
    let response = e.response
    if (!response) {
      return e
    }
    if (response.status === 400) {
      Message.error(response.data.detail)
    }
    if (response.status === 401) {
      Message.error(response.data.detail)
    }
    if (response.status === 500) {
      Message.error(response.data.detail)
    }
    return response
  })
}

const get = (path, params) => {
  return request('GET', path, params, null)
}

const post = (path, data) => {
  return request('POST', path, {}, data, null)
}

const put = (path, data) => {
  return request('PATCH', path, {}, data, null)
}

const del = (path, data) => {
  return request('DELETE', path, {}, data, null)
}

export default {
  baseUrl,
  staticUrl,
  request,
  get,
  post,
  put,
  delete: del,
  getBaseUrl,
}
