<template>
  <div class="footer container-fluid">
    <!-- pc -->
    <div class="pc-footer nav-wrap container">
      <nav class="footer-nav">
        <div class="nav-item" v-for="(item, index) in navList" :key="index">
          <a href="" class="nav-title">
            <router-link :to="item.path">
              {{ $t(item.name) }}
            </router-link>
          </a>
          <ul v-if="item.children.length > 0">
            <li v-for="(i, n) in item.children" :key="n">
              <a
                v-if="i.path.substring(0, 4) == 'http'"
                target="_blank"
                :href="i.path"
                >{{
                  i["name_" + $i18n.locale]
                    ? i["name_" + $i18n.locale]
                    : $t(i.name)
                }}</a
              >
              <router-link v-else :to="i.path">{{
                i["name_" + $i18n.locale]
                  ? i["name_" + $i18n.locale]
                  : $t(i.name)
              }}</router-link>
            </li>
          </ul>
        </div>
        <div @click="handleClick(0)" class="nav-item">
          {{ $t("광고/제휴 문의") }}
        </div>
        <!-- <div @click="handleClick(1)" class="nav-item">{{ $t('기업윤리 신고센터') }}</div> -->
        <div class="nav-highlight" @click="goPrivacy()">
          {{ $t("개인정보 처리방침") }}
        </div>
        <!-- <div class="nav-item">{{ $t('팡스카이 스토어') }}</div> -->
        <div @click="handleClick(2)" class="nav-item">
          {{ $t("게임 고객센터") }}
        </div>
      </nav>
    </div>
    <!-- mb -->
    <div class="mb-footer container">
      <!-- <el-collapse @change="handleChange">
        <el-collapse-item
         v-for="(item,index) in navList"
         :key="index"
         :title="$t(item.name)" 
         :name="item.index"
         >
          <div v-if="item.children.length>0" >
            <p v-for="(i,n) in item.children" :key="n">
              <router-link :to="i.path">{{i['name_' + $i18n.locale]?i['name_' + $i18n.locale]:$t(i.name)}}</router-link>
            </p>
          </div>
        </el-collapse-item>
      </el-collapse> -->
      <div
        :style="{
          backgroundImage:
            'url(' + (currentIndex === index ? tabbgUrlClick : tabbgUrl) + ')'
        }"
        class="tab"
        v-for="(item, index) in navList"
        :key="index"
        @click="navClick(index)"
      >
        <div class="tab-title">
          <div>{{ $t(item.name) }}</div>
        </div>
        <div
          class="tab-content"
          v-for="(i, n) in item.children"
          :key="n"
          :class="{ active: currentIndex === index }"
        >
          <a
            v-if="i.path.substring(0, 4) == 'http'"
            target="_blank"
            :href="i.path"
            >{{
              i["name_" + $i18n.locale] ? i["name_" + $i18n.locale] : $t(i.name)
            }}</a
          >
          <router-link v-else :to="i.path">{{
            i["name_" + $i18n.locale] ? i["name_" + $i18n.locale] : $t(i.name)
          }}</router-link>
        </div>
      </div>
      <div @click="handleClick(0)" class="bottom-item">
        {{ $t("광고/제휴 문의") }}
      </div>
      <!-- <div @click="handleClick(1)" class="bottom-item">{{ $t('기업윤리 신고센터') }}</div> -->
      <div class="bottom-highlight" @click="goPrivacy()">
        {{ $t("개인정보 처리방침") }}
      </div>
      <!-- <div class="bottom-item">{{ $t('팡스카이 스토어') }}</div> -->
      <div @click="handleClick(2)" class="bottom-item">
        {{ $t("게임 고객센터") }}
      </div>
    </div>
    <!-- mb end -->
    <div class="copyright_wrap">
      <div class="pc-wrap-box container">
        <el-row>
          <el-col :span="4"><a class="logo" href=""></a></el-col>
          <el-col :span="16">
            <p class="copyright">
              {{
                $t(
                  "(주)팡스카이 대표이사 지헌민, 대표이사 조호현, 사업자등록번호 : 119-86-62250, 통신판매업 신고번호 : 제 2014-서울강남-01064호"
                )
              }}
              <br />
              {{
                $t(
                  "주소 : 서울시 강남구 논현로80길 25(역삼동,정우빌딩), 전화 : 070-4658-9400, 팩스 : 070-8233-9383"
                )
              }}
              <br />Copyright © PangSky Corp. All rights reserved.
            </p>
          </el-col>
          <el-col :span="4">
            <ul class="copyright-logo">
              <li>
                <a class="item" href="https://www.instagram.com/pangsky_games/"
                  ><img src="../assets/img/ico_ico01_foot.png" alt="" srcset=""
                /></a>
              </li>
              <li>
                <a class="item" href="https://blog.naver.com/pangskycorp"
                  ><img src="../assets/img/ico_ico03_foot.png" alt="" srcset=""
                /></a>
              </li>
              <li>
                <a
                  class="item"
                  href="https://www.youtube.com/channel/UCxCwF6WJ_ttEj-SykS_rtNw"
                  ><img src="../assets/img/ico_ico02_foot.png" alt="" srcset=""
                /></a>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <div class="mb-wrap-box">
        <p>
          <a class="item" href="https://www.instagram.com/pangsky_games/"
            ><img
              src="../assets/img/icn_footer_facebook_round.svg"
              alt=""
              srcset=""
          /></a>
          <a class="item" href="https://blog.naver.com/pangskycorp"
            ><img
              src="../assets/img/icn_footer_linkedin_round.svg"
              alt=""
              srcset=""
          /></a>
          <a
            class="item"
            href="https://www.youtube.com/channel/UCxCwF6WJ_ttEj-SykS_rtNw"
            ><img
              src="../assets/img/icn_footer_youtube_round.svg"
              alt=""
              srcset=""
          /></a>
        </p>
        <p class="mb-logo">
          <router-link :to="{ path: 'home' }">
            <img src="../assets/img/logo_black.png" alt="" srcset="" />
          </router-link>
        </p>
        <p>Copyright © PangSky Corp. All rights reserved.</p>
      </div>
    </div>
    <!-- 弹窗 -->
    <FooterDialog
      v-model="visible"
      :show-modalconfirm="visible"
      @oncilckConfirm="clickConfirmBtn"
      :type="clickType"
      title="标题"
      ryt-width="46.88%"
      ref="ModalRejectclick"
    >
    </FooterDialog>
    <!-- end -->
    <!--  -->
  </div>
</template>
<script>
import FooterDialog from "../components/FooterDialog";
import request from "../api/request";
export default {
  name: "Footer",
  components: {
    FooterDialog
  },
  data() {
    return {
      currentIndex: null,
      tabbgUrl: require("../assets/img/jiahao.svg"),
      tabbgUrlClick: require("../assets/img/jianhao.svg"),
      visible: false,
      clickType: 0,
      navList: [
        {
          name: "소개",
          path: "/home",
          isSubshow: false,
          children: [
            {
              name: "팡스카이소개",
              path: "/Introduction"
            }
          ]
        },
        {
          name: "게임",
          path: "/home",
          isSubshow: false,
          children: []
        },
        {
          // name: "미디어",
          name: "기업소식",
          path: "/news",
          isSubshow: false,
          children: [
            {
              name: "보도자료",
              path: "/news"
            },
            {
              // name: "미디어 행사",
              name: "공고",
              path: "/mediaactivity"
            }
          ]
        },
        {
          name: "문화",
          path: "/culture",
          isSubshow: false,
          children: [
            {
              name: "기업문화",
              path: "/culture"
            },
            {
              name: "복지",
              path: "/welfare"
            }
            // {
            //   name:"팡스카이 소식",
            //   path: "/enterprisenews",
            // }
          ]
        },
        {
          name: "채용",
          path: "/recruitment",
          isSubshow: false,
          children: [
            {
              name: "채용 소개",
              path: "/recruitment"
            }
          ]
        },
        {
          name: "투자정보",
          path: "/message",
          isSubshow: false,
          children: [
            {
              name: "기업지배구조",
              path: "/message"
            }
          ]
        }
      ]
    };
  },
  mounted() {
    this.getMenu();
  },
  methods: {
    clickConfirmBtn() {
      //点击了确认弹窗的确认按钮触发的事件
      console.log("请继续点击了confirm弹框确定按钮的逻辑");
      this.ModalRejectclick.isshow(); //关闭弹窗
    },
    handleClick(type) {
      this.$refs.ModalRejectclick.show = true;
      this.clickType = type;
    },
    handleChange(val) {
      console.log(val);
    },
    navClick(index) {
      if (this.currentIndex == index) {
        this.currentIndex = null;
      } else {
        this.currentIndex = index;
      }
    },
    getMenu() {
      let _this = this;
      request
        .get("/banners", {
          banner_type__in: "10",
          limit: "7"
        })
        .then(function(res) {
          if (res.status == 200) {
            let data = res.data.results;
            let result = data.map(item => {
              let res = {
                name: item[`title_${_this.$i18n.locale}`],
                path: item.url
              };
              for (let i in _this.$i18n.availableLocales) {
                let locale = _this.$i18n.availableLocales[i];
                res["name_" + locale] = item["title_" + locale];
              }
              return res;
            });
            _this.navList[1].children = result;
          }
        });
    },
    goPrivacy() {
      this.$router.push("/privacy");
    }
  }
};
</script>
<style scoped>
.tab {
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 16px 16px;
}
.tab-title {
  font-size: 16px;
  color: #000;
  padding: 12px 0;
  font-weight: 300;
}
.tab-title div {
  flex-grow: 1;
}

.tab-title div:nth-child(2) {
  text-align: right;
}

.tab-content {
  padding: 10px 0;
  background-color: #fff;
  line-height: 18px;
  display: none;
  transition: 0.3s;
}
.tab-content.active {
  display: block;
}

.footer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-top: 1px solid #e0e0e0;
  padding: 30px 0 0 0;
}
.footer .nav-wrap {
  padding-bottom: 30px;
}
.footer-nav .nav-item:nth-child(6) {
  visibility: hidden;
}
.mb-footer .tab:nth-child(6) {
  display: none;
}
.footer .nav-wrap .footer-nav {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: border-box;
  overflow: hidden;
}
.footer .nav-wrap .footer-nav .nav-item {
  float: left;
  flex: 0 0 16.66%;
  box-sizing: border-box;
  font-weight: bolder;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 30px;
}
.footer .nav-wrap .footer-nav .nav-highlight {
  float: left;
  flex: 0 0 16.66%;
  box-sizing: border-box;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  font-size: 20px;
  color: #457af3;
  text-decoration: underline;
  text-underline-offset: 5px;
}
.footer .nav-wrap .footer-nav .nav-item .nav-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  font-size: 18px;
  line-height: 1.4;
  color: #000;
  text-decoration: none;
}
.footer .nav-wrap .footer-nav .nav-item ul {
  overflow: hidden;
  margin-bottom: 40px;
}
.footer .nav-wrap .footer-nav .nav-item ul li a {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 0;
  font-size: 14px;
  line-height: 18px;
  color: #969696;
  text-decoration: none;
}
.footer .nav-wrap .footer-nav .nav-item ul li a:hover {
  color: #000;
}
.footer .copyright_wrap .pc-wrap-box {
  display: flex;
  padding: 32px 0 30px 0;
}
.footer .copyright_wrap {
  border-top: 1px solid #e0e0e0;
  box-sizing: border-box;
}
.footer .copyright_wrap .copyright-logo {
}
.footer .copyright_wrap .copyright-logo .item {
  float: left;
}
.footer .copyright_wrap .logo {
  display: block;
  width: 15rem;
  height: 30px;
  background: url("../assets/img/logo_black.png") no-repeat center left;
  background-size: contain;
  transition: opacity 0.4s;
}
.footer .copyright_wrap .copyright {
  font-size: 14px;
  color: #6a6a6a;
  opacity: 0.5;
  padding-left: 60px;
}
.footer .copyright_wrap .copyright-logo {
  display: flex;
  float: right;
}
.footer .copyright_wrap .copyright-logo .item {
  width: 30px;
  height: 30px;
  margin: 0 5px;
}
.footer .copyright_wrap .copyright-logo .item img {
  width: 100%;
}
.mb-footer {
  display: block !important;
}
.pc-footer {
  display: none !important;
}
.pc-wrap-box {
  display: none !important;
}
.mb-wrap-box {
  display: block !important;
  text-align: center;
}
.mb-wrap-box .mb-logo {
  margin-top: 30px;
}
.mb-wrap-box .mb-logo a img {
  width: 100%;
  height: 18px;
  margin: 0;
}
@media only screen and (min-width: 767px) {
  .mb-footer {
    display: none !important;
  }
  .pc-footer {
    display: block !important;
  }
  .pc-wrap-box {
    display: block !important;
  }
  .mb-wrap-box {
    display: none !important;
  }
  .footer {
    padding-top: 60px;
  }
}
.pc-footer a {
  color: #333;
  text-decoration: none;
  font-weight: bolder;
}
.mb-footer a {
  color: #969696;
  text-decoration: none;
}
.mb-footer .bottom-item {
  margin: 12px 0 22px 0;
  font-weight: bold;
}
.mb-footer .bottom-highlight {
  margin: 12px 0 22px 0;
  font-weight: bold;
  font-size: 17px;
  color: #457af3;
  text-decoration: underline;
  text-underline-offset: 5px;
}
.mb-wrap-box {
  text-align: center;
  padding: 20px 0;
}
.mb-wrap-box a {
  display: inline-block;
}
.mb-wrap-box a img {
  width: 40px;
  height: 40px;
  margin: 0 10px;
}
.mb-wrap-box p:nth-child(3) {
  color: #b0b0b0;
}
.el-collapse-item__header {
  border-bottom: 1px solid#fff !important;
  font-weight: bold;
}
.el-collapse {
  border-bottom: 1px solid #fff !important;
  border-top: #fff !important;
}
.el-collapse-item__wrap {
  border-bottom: 1px solid #fff !important;
}
</style>
