import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
export default new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "home",
      component: () => import("../views/Home/Home.vue"),
      meta: {
        headerShow: true,
      },
    },
    {
      path: "/introduction",
      name: "introduction",
      component: () => import("../views/Introduction/Introduction.vue"),
      meta: {
        headerShow: false,
      },
    },
    {
      path: "/news",
      name: "news",
      component: () => import("../views/Media/News.vue"),
      meta: {
        headerShow: false,
      },
    },
    {
      path: "/mediaactivity",
      name: "mediaactivity",
      component: () => import("../views/Media/MediaActivity.vue"),
      meta: {
        headerShow: false,
      },
    },
    {
      path: "/culture",
      name: "culture",
      component: () => import("../views/Culture/Culture.vue"),
      meta: {
        headerShow: false,
      },
    },
    {
      path: "/welfare",
      name: "welfare",
      component: () => import("../views/Culture/Welfare.vue"),
      meta: {
        headerShow: false,
      },
    },
    {
      path: "/recruitment",
      name: "recruitment",
      component: () => import("../views/Recruitment/Recruitment.vue"),
      meta: {
        headerShow: false,
      },
    },
    {
      path: "/message",
      name: "message",
      component: () => import("../views/InvestmentMessage/Message.vue"),
      meta: {
        headerShow: false,
      },
    },
    {
      path: "/detail/:id",
      name: "detail",
      component: () => import("../views/Detail/Detail.vue"),
      meta: {
        headerShow: false,
      },
    },
    {
      path: "/privacy",
      name: "privacy",
      component: () => import("../views/Privacy/Privacy.vue"),
      meta: {
        headerShow: false,
      },
    },
    {
      path: "/enterprisenews",
      name: "enterprisenews",
      component: () => import("../views/Media/EnterpriseNews.vue"),
      meta: {
        headerShow: false,
      },
    },
  ],
});
